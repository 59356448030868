import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import Masthead from "../../../../Components/Masthead";
import { useNavigate } from "react-router-dom";

const ConsentThanks = () => {
    const navigate = useNavigate()
    const consentType = window.localStorage.getItem('consentType')
  return (
    <>
    <Masthead/>
        <Box sx={{mt: 5}}>
            <Box sx={{textAlign: 'center'}}>
                    <VerifiedIcon sx={{color: '#05a005', fontSize: '60px'}}/>
                </Box>
            <Box sx={{mt: 2, display: 'flex', justifyContent: 'center'}}>
                <Box sx={{width: '50%'}} sm={{width: '100%'}}>
                    {consentType == 'booking' ? <>
                    <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>Thank you, your booking request has been submitted.</Typography>
                    <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>We will check for a suitable match and come back to you very soon!</Typography>
                    </> : <>
                    <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>We have received all the information we need to process your booking.</Typography>
                    <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>Your will shortly receive an email with an invoice and a link to make the payment. We request yout to make this payment immediately. This is the last step in the confirmation process and we will confirm your booking to the dog sitter as soon as the payment is complete.</Typography>
                    </>}
                    <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>Please email us at contact@dogstays.lu if you have any questions.</Typography>
                </Box>
            </Box>
            <Grid xs={12} sx={{textAlign: 'center'}}>
                <Button className="default-btn" variant="contained" sx={{ mt: 5, mb: 2, width: "25%" }} onClick={() => navigate('/dashboard')} > Go To My DogStays Dashboard </Button> 
            </Grid>
        </Box>
    </>
  );
};

export default ConsentThanks;
