import { useEffect, useState } from "react";
import './style.css'
import { ImageUpload } from "../../Utils/Api";
import { Typography } from "@mui/material";

const EditMultiUpload = ({text, imageAssets, uploadedImages, filteredEditImg}) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [alreadyUploadedImages, setAlreadyUploadedImages] = useState(uploadedImages)

    useEffect(() => {
        uploadedImages.length > 0 &&
        setAlreadyUploadedImages(uploadedImages)
    },[uploadedImages])

  const InputChange = (e) => {
    // --For Multiple File Input
    let dynamicLimit =  3 - alreadyUploadedImages.length;
    let images = [];
    for (let i = 0; i < dynamicLimit; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: i,
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
    imageAssets(images)
    filteredEditImg(alreadyUploadedImages)
  };

  const DeleteAlreadyUploadedImages = (index) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = alreadyUploadedImages.splice((index, 1));
      setAlreadyUploadedImages(result)
      filteredEditImg(result)
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    }
  };

  return (
    <div className="card-body">
    <div className="kb-data-box">
      <div className="kb-modal-data-title">
        <div className="kb-data-title">
          <h6>Please upload a profile photo for your DogStays account</h6>
        </div>
      </div>
        <div className="kb-file-upload">
          <div className="file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              onChange={InputChange}
              multiple
              accept=".png, .jpg, .jpeg"
            />
            <span style={{textAlign: 'center'}}>
              Drag and drop or{" "}
              <span className="file-link">Choose your files</span>
              <p style={{fontSize: '14px', fontFamily: 'Brandon'}}>{text}</p>
            </span>
          </div>
        </div>
        <Typography fontFamily="Brandon" sx={{ width: "100%", mb: 1, mt: 3, fontSize: "16px", textDecoration: "underline", }}> Current Uploads </Typography>
        <div className="kb-attach-box mb-3">
            {alreadyUploadedImages.map((item, index) => {
                return(
                    <div className="file-atc-box">
                  <div className="file-image">
                    <img src={item} alt="" />
                  </div>
            
                <div className="file-detail">
                  <div className="file-actions">
                     <button
                      type="button"
                      className="file-action-btn"
                      onClick={() => DeleteAlreadyUploadedImages(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
                )
            })}
        
        </div>
        {selectedfile.length > 0 && <Typography fontFamily="Brandon" sx={{ width: "100%", mb: 1, mt: 3, fontSize: "16px", textDecoration: "underline", }} > New Uploads </Typography>}
        <div style={{display: 'flex', gap: "20px"}}>
          {selectedfile.map((data, index) => {
            const {
              id,
              filename,
              fileimage,
            } = data;
            return (
              <div className="file-atc-box" key={id}>
                {filename.match(/.(jpg|jpeg|png|svg|gif|tiff)$/i) && (
                  <div className="file-image">
                    {" "}
                    <img src={fileimage} alt="" />
                  </div>
                )}
                <div className="file-detail">
                  <div className="file-actions">
                     <button
                      type="button"
                      className="file-action-btn"
                      onClick={() => DeleteSelectFile(id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        }
        </div>
    </div>
  </div>
  );
};

export default EditMultiUpload;
