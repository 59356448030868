import { useState } from "react";
import './style.css'
import { ImageUpload } from "../../Utils/Api";

const MultiUpload = ({text, imageAssets, limit}) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [images, SetImages] = useState([]);


  const InputChange = (e) => {
    // --For Multiple File Input
    let dynamicLimit =  limit == 1 ? 1 : limit > 4 ? e.target.files.length : 3
    let images = [];
    for (let i = 0; i < dynamicLimit; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: i,
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
    imageAssets(images)
    SetImages(images)
  };


  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.splice((id, 1));
      SetSelectedFile(result);
    }
  };

  return (
    <div className="card-body">
    <div className="kb-data-box">
      <div className="kb-modal-data-title">
        <div className="kb-data-title">
          <h6>Please upload a profile photo for your DogStays account</h6>
        </div>
      </div>
        <div className="kb-file-upload">
          <div className="file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              onChange={InputChange}
              multiple
              accept=".png, .jpg, .jpeg"
            />
            <span style={{textAlign: 'center'}}>
              Drag and drop or{" "}
              <span className="file-link">Choose your files</span>
              <p style={{fontSize: '14px', fontFamily: 'Brandon'}}>{text}</p>
            </span>
          </div>
        </div>
        <div className="kb-attach-box mb-3">
          {selectedfile.map((data, index) => {
            const {
              id,
              filename,
              fileimage,
            } = data;
            return (
              <div className="file-atc-box" key={id}>
                {filename.match(/.(jpg|jpeg|png|svg|gif|tiff)$/i) ? (
                  <div className="file-image">
                    {" "}
                    <img src={fileimage} alt="" />
                  </div>
                ) : (
                  <div className="file-image">
                    <i className="far fa-file-alt"></i>
                  </div>
                )}
                <div className="file-detail">
                  {/* <h6>{filename}</h6> */}
                  <div className="file-actions">
                     <button
                      type="button"
                      className="file-action-btn"
                      onClick={() => DeleteSelectFile(id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
    </div>
  </div>
  );
};

export default MultiUpload;
