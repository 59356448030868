import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, NativeSelect, Switch, Typography } from "@mui/material"
import { CustomLabelInput, FormInput, InputField, InputTeaxtArea, StyledToggle, StyledToggleButtonGroup } from "../CustomElements"
import { useEffect, useState } from "react"
import { BackBtn } from "../../Assets/assets"
import './style.css'
import DatePicker from "react-multi-date-picker"
import CancelIcon from '@mui/icons-material/Cancel';
import { createBookingReq, getDogList, getPastSittersList } from "../../Utils/Api"
import { useNavigate } from "react-router-dom"
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs"
import AddressModule from "../BookingAddress"
import SnackBar from "../Snackbar"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { weekDaysArr } from "../../helper"
import { calculateOvernightTotalDays } from "../../Utils/helper"
import Select from 'react-select'

const BookingRequestForm = () => {
  const [activeModule, setActiveModule] = useState(0)
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [dogList, setDogList] = useState([])
  const [modifiedDaycareData, setModifiedDaycareData] = useState([]);
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0);
  const navigate = useNavigate()
  const [addressData, setAddressData] = useState()
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
  const [changeGlobalStartDate, setChangeGlobalStartDate] = useState()
  const [changeGlobalEndDate, setChangeGlobalEndDate] = useState()
  const [isGlobalChangeDateEnabled, setIsGlobalChangeDateEnabled] = useState(false)
  const [preferedSittersList, setPreferedSittersList] = useState([]);
  const [basicDetails, setBasicDetails] = useState({
    dogs: [], //ids of dogs
    preferred_sitters: [], // ids
    preferred_sitters_text: '',
    available_for_video_call: '',
    service_type: '', // overnight_boarding daycare home_visit
    other_details: '',
    comfortable_splitting_booking: '',
    sitter_caring_for_another_dog: ''
  })
  const { dogs, preferred_sitters, preferred_sitters_text, available_for_video_call, service_type} = basicDetails

  const [daycareData, setDaycareData] = useState({
    daycare_dates: modifiedDaycareData,
    daycare_dates_flexible: '',
    comfortable_with_sitter_dog: '',
    comfortable_with_sitter_dog_text: '',
    comfortable_with_sitter_cat: '',
    comfortable_with_sitter_cat_text: '',
    comfortable_with_sitter_children: '',
    comfortable_with_sitter_children_text: '',
    ideal_start_date: ''
  })

  const [overnightData, setOvernightData] = useState({
    comfortable_with_sitter_dog: '',
    comfortable_with_sitter_dog_text: '',
    comfortable_with_sitter_cat: '',
    comfortable_with_sitter_cat_text: '',
    comfortable_with_sitter_children: '',
    comfortable_with_sitter_children_text: '',
    overnight_start_date_time: {
      date: '',
      time: ''
    },
    overnight_end_date_time: {
      date: '',
      time: ''
    }
  })


  const [homeVistData, setHomeVistData] = useState({
    number_of_home_care_visits: '',
    home_care_activities: '',
  })

  const {number_of_home_care_visits, home_care_activities} = homeVistData

  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [selectedAction, setSelectedAction] = useState(0);

  const resetOvernightData = () => {
    setActiveModule(0)
    setOvernightData({
      comfortable_with_sitter_dog: '',
      comfortable_with_sitter_dog_text: '',
      comfortable_with_sitter_cat: '',
      comfortable_with_sitter_cat_text: '',
      comfortable_with_sitter_children: '',
      comfortable_with_sitter_children_text: '',
      overnight_start_date_time: {
        date: '',
        time: ''
      },
      overnight_end_date_time: {
        date: '',
        time: ''
      }
    })
  }

  const resetDaycareData = () => {
    setActiveModule(0)
    setModifiedDaycareData([])
    setDaycareDaysTime(0)
    setDayCareSelectDates([])
    setDaycareData({
      daycare_dates: modifiedDaycareData,
      daycare_dates_flexible: '',
      comfortable_with_sitter_dog: '',
      comfortable_with_sitter_dog_text: '',
      comfortable_with_sitter_cat: '',
      comfortable_with_sitter_cat_text: '',
      comfortable_with_sitter_children: '',
      comfortable_with_sitter_children_text: '',
      ideal_start_date: ''
    })
  }

 
  useEffect(() => {
    getDogList().then((res) => {
      setDogList(res.data.data)
    })
    getPastSittersList().then((res) => {
      let sitterList = res.data.data
      const modifiedArr = []
      sitterList.map((item) => {
        modifiedArr.push({value: item.sitter_id, label: item.sitter_name})
      })
      setPreferedSittersList(modifiedArr)
      console.log(modifiedArr, 'modifiedArr')
    })
  }, [])

  useEffect(() => {
    setBasicDetails({...basicDetails, dogs: basicDetails.dogs})
  }, [dogs]);

  useEffect(() => {
  const time = calculateOvernightTotalDays(overnightData.overnight_start_date_time, overnightData.overnight_end_date_time)
  setOvernightTimeDiff(time)
  }, [overnightData]);

  const dayType = []

  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if(item.day_type !== ''){
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map(day => {
        if (day === 'Half Day') {return 0.5}
        else if (day === 'Full Day') {return 1}
        else if (day === 'One Half') {return 1.5}
        return day;
      });
      let totalDaysSum = 0;
      convertedArray.forEach( num => {totalDaysSum += num})
      setDaycareDaysTime(totalDaysSum)
    return
    })
  }, [modifiedDaycareData]);

  function calculateTimeDifference(startTime, endTime) {
    if(startTime && endTime){
    const [hours1, minutes1] = startTime.split(':').map(Number);
    const [hours2, minutes2] = endTime.split(':').map(Number);
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;
    let timeDifference = totalMinutes2 - totalMinutes1;
    const hoursDifference = Math.floor(timeDifference / 60);
    const minutesDifference = timeDifference % 60;
   if(hoursDifference <= 0){
    setSnackbar({...snackbar, isOpen: true, message: 'Please select the proper time', type: 'error'})
   }else{
    return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
   }
  } else return
  }

  const calculateHoursToMins = (hours) => {
    if(hours){
      const [hour, minutes] = hours.split(':').map(Number);
      const totalMinutes = hour * 60 + minutes;
      return totalMinutes
    }
  }

  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    const newDateObject = convertDate.filter(date => !modifiedDaycareData.some(item => item.date === date))
    const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date));
    const modifiedJson = newDateObject.map((item) => {
      const data = {
        date: item,
        startTime: '',
        endTime: '',
        totalHours: '',
        day_type: '' // Full or half day
      }
      mappedData.push(data)
    })
    setDayCareSelectDates(convertDate)
    // setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ])
    mappedData.length > 0 ? setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ]) : setModifiedDaycareData(filteredArr)
  }

  const handleCreateDaycareBooking = () => {
    try {
      if(selectedWeekdays.length > 0 && daycareData.ideal_start_date == ''){
        setSnackbar({...snackbar, isOpen: true, message: 'Please select Ideal start date', type: 'error'})
      }else {
        const serviceBasedPayload = selectedAction == 2 ? 
      {daycare_dates: modifiedDaycareData} : {week_days: selectedWeekdays, ideal_start_date: daycareData.ideal_start_date}

      addressData.location_type.length == 0 || daycareData.daycare_dates_flexible == '' ? 
      setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'})
      : ((selectedAction == 2 ) &&  modifiedDaycareData.length === 0) ? 
          setSnackbar({...snackbar, isOpen: true, message: 'Please Select The Dates', type: 'error'})
        :
      createBookingReq({
        dogs: dogs,
        preferred_sitters: preferred_sitters,
        preferred_sitters_text: preferred_sitters_text,
        available_for_video_call: available_for_video_call,
        service_type: 'daycare',
        location_type: addressData.location_type,
        manual_address: addressData.manual_address,
        parent_address: addressData.parent_address,
        daycare_dates_flexible: daycareData.daycare_dates_flexible,
        comfortable_with_sitter_dog: daycareData.comfortable_with_sitter_dog,
        comfortable_with_sitter_dog_text: daycareData.comfortable_with_sitter_dog_text,
        comfortable_with_sitter_cat: daycareData.comfortable_with_sitter_cat,
        comfortable_with_sitter_cat_text: daycareData.comfortable_with_sitter_cat_text,
        comfortable_with_sitter_children: daycareData.comfortable_with_sitter_children,
        comfortable_with_sitter_children_text: daycareData.comfortable_with_sitter_children_text,
        comfortable_splitting_booking: basicDetails.comfortable_splitting_booking,
        sitter_caring_for_another_dog: basicDetails.sitter_caring_for_another_dog,
        other_details: basicDetails.other_details,
        ...serviceBasedPayload

      }).then((res) => {
        res.data.response_code == 80 ?  (() => {navigate('/thanks'); window.localStorage.setItem('consentType', 'booking')})() : setSnackbar({...snackbar, isOpen: true, message: res.data.developer_message, type: 'error'})
        
      })
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  const handleCreateOvernightBooking = () => {
    try {
      addressData.location_type.length == 0  || overnightData.overnight_start_date_time.date == '' || overnightData.overnight_start_date_time.time == '' || overnightData.overnight_end_date_time.date == '' || overnightData.overnight_end_date_time.time == ''? 
      setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'})
      :
      createBookingReq({
        dogs: dogs,
        preferred_sitters: preferred_sitters,
        preferred_sitters_text: preferred_sitters_text,
        available_for_video_call: available_for_video_call,
        service_type: 'overnight_boarding',
        location_type: addressData.location_type,
        manual_address: addressData.manual_address,
        parent_address: addressData.parent_address,
        comfortable_with_sitter_dog: overnightData.comfortable_with_sitter_dog,
        comfortable_with_sitter_dog_text: overnightData.comfortable_with_sitter_dog_text,
        comfortable_with_sitter_cat: overnightData.comfortable_with_sitter_cat,
        comfortable_with_sitter_cat_text: overnightData.comfortable_with_sitter_cat_text,
        comfortable_with_sitter_children: overnightData.comfortable_with_sitter_children,
        comfortable_with_sitter_children_text: overnightData.comfortable_with_sitter_children_text,
        overnight_start_date_time: {
          date: overnightData.overnight_start_date_time.date,
          time: overnightData.overnight_start_date_time.time
        },
        overnight_end_date_time: {
          date: overnightData.overnight_end_date_time.date,
          time: overnightData.overnight_end_date_time.time
        },
        comfortable_splitting_booking: basicDetails.comfortable_splitting_booking,
        sitter_caring_for_another_dog: basicDetails.sitter_caring_for_another_dog,
        other_details: basicDetails.other_details,
      }).then((res) => {
        res.data.response_code == 80 ?  (() => {navigate('/thanks'); window.localStorage.setItem('consentType', 'booking')})() : setSnackbar({...snackbar, isOpen: true, message: res.data.developer_message, type: 'error'})
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCreateHomeVisitBooking = () => {
    try {
      addressData.location_type.length == 0 || number_of_home_care_visits == ''  || home_care_activities == '' ? 
      setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'})
      :
      createBookingReq({
        dogs: dogs,
        preferred_sitters: [],
        preferred_sitters_text: preferred_sitters_text,
        available_for_video_call: available_for_video_call,
        service_type: 'home_visit',
        location_type: addressData.location_type,
        manual_address: addressData.manual_address,
        parent_address: addressData.parent_address,
        number_of_home_care_visits: number_of_home_care_visits,
        home_care_activities: home_care_activities,
      }).then((res) => {
        res.data.response_code == 80 ?  (() => {navigate('/thanks'); window.localStorage.setItem('consentType', 'booking')})() : setSnackbar({...snackbar, isOpen: true, message: res.data.developer_message, type: 'error'})

      })
    } catch (error) {
      console.log(error)
    }
  }

  const setGlobalStartDate = (time) => {
    let arr = []
    if(isGlobalChangeDateEnabled && time){
      setChangeGlobalStartDate(time.format('HH:mm'))
    modifiedDaycareData.map((item) => {
      return arr.push({
        ...item,
        startTime: time.format('HH:mm'),
        totalHours: calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate),
        day_type: calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 301 ? "Full Day" : "Half Day"})
    })
    setModifiedDaycareData(arr)}
    else return
  }
  const setGlobalEndDate = (time) => {
    let arr = []
    if(isGlobalChangeDateEnabled && time){
      setChangeGlobalEndDate(time.format('HH:mm'))
    modifiedDaycareData.map((item) => {
      return arr.push({
        ...item,
        endTime: time.format('HH:mm'),
        totalHours: calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm')),
         day_type: calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"
       })
    })
    setModifiedDaycareData(arr)}
    else return
  }

  
return(
    <>
    {activeModule == 0 && 
      <>
        {window.location.pathname == '/dashboard/booking/create' && <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
        </Box>}
    <h1 style={{ fontFamily: "Amatic", textAlign: "center", margin: "0"}}> NEW BOOKING REQUEST </h1> 
    <Grid container>
        <Grid item xs={12}>
        <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                  <CustomLabelInput htmlFor="hear_from_source">
                  Which dog is this request is for? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <FormGroup sx={{flexDirection: 'row'}}>
                    {dogList.map((item) => {
                      return <FormControlLabel label={item.name}
                       control={
                       <Checkbox
                        value={item.dog_id}
                        checked={dogs.includes(item.dog_id) ? true : false}
                        onChange={() => {dogs.includes(item.dog_id) ? setBasicDetails({...basicDetails, dogs: dogs.filter((value) => value != item.dog_id)}) : setBasicDetails({...basicDetails, dogs: [...dogs, item.dog_id]})}}
                        sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
                    })}
                  </FormGroup>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Here is a list of dog sitters you have booked with. Please select all the sitters you are comfortable with for this request. If you don’t see the name of a dog sitter you want us to check for, please type in the name of the dog sitter. You can enter multiple names separated by a comma (,).
                </CustomLabelInput>
                <Select
                  className='select-font'
                  multiple
                  options={preferedSittersList}
                  onChange={(value) => {
                    setBasicDetails((prevDetails) => {
                      const selectedSitterId = [];
                      selectedSitterId.push(value.value);
                      return { ...prevDetails, preferred_sitters: selectedSitterId };
                    });
                  }}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
                Sitter Names
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Sitter's Name"
                    value={basicDetails.preferred_sitters_text}
                    onChange={(e) => setBasicDetails({
                      ...basicDetails,
                      preferred_sitters_text: e.target.value
                    })}
                  />
                </FormInput>
            </Grid>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                 We always try and arrange a face-to-face meeting for you and your dog to meet the dog sitter but in case this is difficult for some reason, 
                 would a video call be OK for you? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="hear_from_source"
                  id="hear_from_source"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={available_for_video_call}
                  onChange={(e) => setBasicDetails({
                    ...basicDetails,
                    available_for_video_call: e.target.value
                  })}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               For very long overnight bookings we sometimes need to use the services of more than one dog sitter. Are you comfortable with this option? <span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 value={basicDetails.comfortable_splitting_booking}
                 onChange={(e) => {setBasicDetails({...basicDetails, comfortable_splitting_booking: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the dog sitter is caring for another dog on some of your dates?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 value={basicDetails.sitter_caring_for_another_dog}
                 onChange={(e) => {setBasicDetails({...basicDetails, sitter_caring_for_another_dog: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           <Grid item xs={12}>
           <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                  Is there anything else you would like us or the dog sitter to know?
                </CustomLabelInput>
                <InputTeaxtArea
                value={basicDetails.other_details}
                onChange={(e) => {setBasicDetails({...basicDetails, other_details: e.target.value})}}
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
              </FormInput>
           </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Which service would you like to book?
                </CustomLabelInput>
                <Box sx={{display: 'flex', gap: "10px", marginTop:'5px'}}>
                <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => {dogs.length == 0 || available_for_video_call == '' || basicDetails.comfortable_splitting_booking == '' ? setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'}) : setActiveModule(1)}}> Day Care</Button>
                <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => {dogs.length == 0 || available_for_video_call == '' || basicDetails.comfortable_splitting_booking == '' ? setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'}) : setActiveModule(2)}}> Overnight Booking </Button>
                {/* <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => {dogs.length == 0 || available_for_video_call == '' ? setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'}) : setActiveModule(3)}}> Home Visits </Button> */}
                </Box>
              </FormInput>
            </Grid> 
            <Grid xs={12}>
              <ul className="enquiry-links">
                <li>You can see details of our pricing for each service <a href="https://www.dogstays.lu/pricing" target="blank">here</a></li>
                <li>Read answers to some common questions in our FAQs <a href="https://www.dogstays.lu/faqs-parents" target="blank">here</a></li>
              </ul>
            </Grid>
    </Grid>
      </>
    }
    {activeModule == 1 && 
    <>
    <div>
    <Box className="wrap-back-btn" onClick={() => {resetDaycareData()}}>{BackBtn()} Back</Box>
      <h2 className="request-type-heading"> Booking Request - DayCare</h2>
    </div>
      <Grid container sx={{marginTop:'10px'}}>
        <AddressModule data={(data) => setAddressData(data)}/>
        {addressData?.location_type.includes('sitter_location') && <Grid container xs={false}  md={12} sx={{gap: '10px'}}>
            <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a dog?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_dog}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_dog: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_dog == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_dog_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_dog_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a cat?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_cat}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_cat: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_cat == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_cat_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_cat_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the sitter's home has a children?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_children}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_children: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_children == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_children_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_children_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
        </Grid>}
        <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                How often do you need this service?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
                <Box sx={{display: 'flex', gap: '16px'}}>
                  <Box className={selectedAction == 2 ? 'selector selected-item' :'selector'} onClick={() => {setSelectedAction(2);setModifiedDaycareData([]); setDayCareSelectDates([]); setSelectedWeekdays([]); setDaycareData({...daycareData, ideal_start_date: ''})}}>
                    <EditCalendarIcon color="#ced4d8"/> One Time
                  </Box>
                  <Box className={selectedAction == 1 ? 'selector selected-item' :'selector'} onClick={() => {setSelectedAction(1); setModifiedDaycareData([]); setDayCareSelectDates([]); setSelectedWeekdays([]); setDaycareData({...daycareData, ideal_start_date: ''})}}>
                    <CalendarTodayIcon color="#ced4d8"/> Repeat Weekly
                  </Box>
                </Box>
             </FormInput>
           </Grid>


           {selectedAction == 1 && <Grid item xs={12} sx={{display:'flex', flexWrap: 'wrap', gap: '20px'}}>
            <Box>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true">
                  For which days?<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <StyledToggleButtonGroup
                  size="small"
                  arial-label="Days of the week"
                  value={selectedWeekdays}
                  sx={{mt: 1, width: '100%'}}
                  onChange={(event, value) => setSelectedWeekdays(value)}
                >
                  {weekDaysArr.map((day, index) => (
                    <StyledToggle key={day.key} value={day.key} aria-label={day.key}>
                      {day.label}
                    </StyledToggle>
                  ))}
                </StyledToggleButtonGroup>
                </FormInput>
              </Box>
              <Box>
              <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
                Ideal start date<span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <InputField
                type="date"
                size="small"
                value={daycareData.ideal_start_date}
                onChange={(e) => {setDaycareData({...daycareData, ideal_start_date: e.target.value})}}
                placeholder="Enter Date"
              />
              </FormInput>
              </Box>
          </Grid>}

          
         {selectedAction == 2 && <> <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Select Dates <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput>
              <DatePicker
                className="date-picker-layout"
                isMulti
                onChange={handledDayCareMultipleDates}
                value={dayCareSelectDates}
                dateFormat="DD-MM-YYYY" 
                minDate={new Date()}
              />
              </FormInput>
          </Grid>
          <p style={{fontFamily: 'Brandon', fontSize: '14px', color: 'red', margin: '10px 0px 0px 0px'}}>Please switch this on if you wish to use the same start time and end time for all dates</p>
              <Grid xs={12} sx={{display: 'flex', gap:'10px'}}>
              <Grid xs={1}>
              <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  Set default Time
                  </CustomLabelInput>
                  <Switch defaultChecked checked={isGlobalChangeDateEnabled} color="warning" onChange={() => {
                    setIsGlobalChangeDateEnabled(!isGlobalChangeDateEnabled)
                    if(isGlobalChangeDateEnabled){
                      setGlobalStartDate()
                      setGlobalEndDate()
                    } else return
                  }}/>
              </FormInput>
              </Grid>
              
              <Grid xs={5}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  Start Time
                  </CustomLabelInput>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                    sx={{marginTop: '5px'}}
                    // value={item.startTime}
                    ampm={false}
                    closeOnSelect={true}
                    onChange={setGlobalStartDate}
                    />
                  </LocalizationProvider>            
                  </FormInput>
                </Grid>
                <Grid xs={5}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  End Time
                  </CustomLabelInput>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                    sx={{marginTop: '5px'}}
                    // defaultValue={dayjs('2022-04-17T18:30')} 
                    // value={item.startTime}
                    minTime={dayjs(changeGlobalStartDate, "HH:mm")}
                    ampm={false}
                    closeOnSelect={true}
                    onChange={setGlobalEndDate}
                    />
                  </LocalizationProvider>            
                  </FormInput>
                </Grid>
              </Grid>
                 <Grid item xs={12}>
                 {modifiedDaycareData.map((item, index) => {
                   return(
                   <div className="events-wrapper">
                     <span onClick={() => {
                       const calenderItems = [...dayCareSelectDates];
                       const modifyItems = [...modifiedDaycareData];
                       calenderItems.splice(index, 1);
                       modifyItems.splice(index, 1);
                       setModifiedDaycareData(modifyItems);
                       setDayCareSelectDates(calenderItems);
                     }}><CancelIcon style={{ color: "#fe7648" }}/></span>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Selected Dates
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         disabled
                         value={item.date}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Start Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                          sx={{marginTop: '5px'}}
                          defaultValue={dayjs('2022-04-17T18:30')} 
                          value={dayjs(`${item.date}T${item.startTime}`)}
                          ampm={false}
                          closeOnSelect={true}
                          onChange={(time) => {
                           const updatedData = { ...item, startTime: time.format('HH:mm'), totalHours: calculateTimeDifference(time.format('HH:mm'), item.endTime)};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                          />
                       </LocalizationProvider>            
                       </FormInput>
                       </Grid>
                       <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       End Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                           sx={{marginTop: '5px'}}
                           defaultValue={dayjs()}
                           value={dayjs(`${item.date}T${item.endTime}`)}
                           ampm={false}
                           minTime={dayjs(item.startTime, 'HH:MM')}
                           onChange={(time) => {
                           const updatedData = { ...item, endTime: time.format('HH:mm'), totalHours: calculateTimeDifference(item.startTime, time.format('HH:mm')), day_type: calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                         />
                       </LocalizationProvider>
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Hours
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Hours"
                         disabled
                         value={item.totalHours}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Days
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Days"
                         disabled
                         value={item.day_type}
                       />
                     </FormInput>
                     </Grid>
                   </div>)
                 })}
                 {modifiedDaycareData.length > 0 && 
                 <Grid sx={{display: 'flex', justifyContent:'end', gap:'15px', marginRight:'10px'}}>
                   {/* <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total hours: ${hours}`}</Typography> */}
                   <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total days: ${daycareDataDaysTime}`}</Typography>
                 </Grid>
                 }
                </Grid>
                </>
          }

       <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Are your dates a little flexible? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="hear_from_source"
                  id="hear_from_source"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                  value={daycareData.daycare_dates_flexible}
                  onChange={(e) => {setDaycareData({...daycareData, daycare_dates_flexible: e.target.value})}}
                >   <option selected disabled value=''>Select</option>
                    <option value='false'>No, my dates cannot change</option>
                    <option value='true'>Yes, my dates can be adjusted for the right dog sitter</option>
                </NativeSelect>
              </FormInput>
          </Grid>
          <Grid item xs={12}>
           <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                  Is there anything else you would like us or the dog sitter to know? 
                </CustomLabelInput>
                <InputTeaxtArea
                value={daycareData.other_details}
                onChange={(e) => {setDaycareData({...daycareData, other_details: e.target.value})}}
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
              </FormInput>
           </Grid>
       <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
       <Button
         type="submit"
         className="default-btn"
         variant="contained"
         sx={{ mt: 3, mb: 2, width: "auto" }}
         onClick={handleCreateDaycareBooking}
       >
         Submit Request
       </Button>
       </Grid>

      </Grid>
    </>
    }
    {activeModule == 2 &&   
          <div>
          <Box className="wrap-back-btn" onClick={() => {resetOvernightData()}}>{BackBtn()} Back</Box>
            <h2 className="request-type-heading"> Booking Request - Overnight Booking</h2>
            <Grid container sx={{marginTop:'10px'}}>
            <AddressModule data={(data) => setAddressData(data)}/>
        {addressData?.location_type.includes('sitter_location') && <Grid container xs={false}  md={12} sx={{gap: '10px'}}>
            <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a dog?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_dog}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_dog: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_dog == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_dog_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_dog_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a cat?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_cat}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_cat: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_cat == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_cat_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_cat_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the sitter's home has a children?
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_children}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_children: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_children == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_children_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_children_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
        </Grid>}

        <Grid container xs={12} sx={{gap:'15px'}}>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Start Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              value={overnightData.overnight_start_date_time.date}
              inputProps={{ min: new Date().toISOString().substring(0,10)}}
              onChange={(e) => {
                setOvernightData({...overnightData, overnight_start_date_time: {...overnightData.overnight_start_date_time, date: e.target.value}, overnight_end_date_time: {...overnightData.overnight_end_date_time, date: e.target.value}})
              }
              }
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Start Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 defaultValue={dayjs()}
                 value={overnightData.overnight_start_date_time.time}
                 onChange={(e) => setOvernightData({...overnightData, overnight_start_date_time: {...overnightData.overnight_start_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

        <Grid container xs={12} sx={{gap:'15px'}}>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              End Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              value={overnightData.overnight_end_date_time.date}
              inputProps={{ min: overnightData.overnight_start_date_time.date }}
              onChange={(e) => setOvernightData({...overnightData, overnight_end_date_time: {...overnightData.overnight_end_date_time, date: e.target.value}})}
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              End Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker 
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 defaultValue={dayjs()}
                 value={overnightData.overnight_end_date_time.time}
                 onChange={(e) => setOvernightData({...overnightData, overnight_end_date_time: {...overnightData.overnight_end_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

          <h4 style={{fontFamily: 'Brandon', fontWeight:'bold', margin: '5px 0px 0px 0px', color: '#00000099', width: '100%', textAlign: 'center'}}>No of days: {overnightTimeDiff}</h4>
        <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleCreateOvernightBooking}
            >
              Submit Request
            </Button>
            </Grid> 

      </Grid>
          </div>
          
    }
    {activeModule == 3 && 
         <div>
         <Box className="wrap-back-btn" onClick={() => {setActiveModule(0)}}>{BackBtn()} Back</Box>
           <h2 className="request-type-heading"> Booking Request - Home Visits</h2>
           <Grid container sx={{marginTop:'10px'}}>
           <Grid item xs={false} sm={6} md={12}>
           <AddressModule data={(data) => setAddressData(data)}/>
        </Grid>
        {/* <Grid item xs={false} sm={2} md={6}> */}
        <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               How many visits would you like in a day? <span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 value={number_of_home_care_visits}
                 onChange={(e) => {setHomeVistData({...homeVistData, number_of_home_care_visits: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="one">One</option>
                 <option value="two">Two</option>
                 <option value="three">Three</option>
               </NativeSelect>
             </FormInput>
           </Grid>
          
        <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              What would you like the dog sitter to do for your dog during the visit? <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <InputTeaxtArea
                minRows={2}
                value={home_care_activities}
                onChange={(e) => {setHomeVistData({...homeVistData, home_care_activities: e.target.value})}}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
            </FormInput>
          </Grid>
          <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleCreateHomeVisitBooking}
            >
              Submit Request
            </Button>
            </Grid> 
      </Grid>
         </div>
         
    }
      <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={3000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
)
}

export default BookingRequestForm