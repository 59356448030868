import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { assests } from "../../Assets/assets";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./style.css";
import MobileMasthead from "./MobileMasthead";
import { subMenu } from "../../helper";

function Masthead() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const authStore = window.localStorage.getItem("AuthStore");
  const parseAuthStore = JSON.parse(authStore);
  const token = window.localStorage.getItem("token");
  const navigate = useNavigate();

  const redirectToProfile = () => navigate("/dashboard/profile");

  const redirectToDashboard = () => navigate("/dashboard");

  const redirectToDogs = () => navigate("/dashboard/dog");

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  let settings = [
    { key: "My Profile", callback: redirectToProfile },
    { key: "My Dogs", callback: redirectToDogs },
    { key: "Dashboard", callback: redirectToDashboard },
    { key: "Logout", callback: logout },
  ];

  useEffect(() => {
    const serialisedData = JSON.parse(authStore);
    if (
      serialisedData?.profile_status != "completed" &&
      serialisedData?.profile_step != "completed"
    ) {
      settings.splice(0, 2);
    }
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <div className="appbar-container">
        <AppBar
          position="static"
          sx={{ background: "white", paddingTop: "10px" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ display: { xs: "flex" }, flexGrow: "2", justifyContent:'center' }}>
                <img src={assests.dogstaysLogo} className="masthead-logo" />
              </Box>

              <Box sx={{ display: { xs: "flex" }, flexGrow: "2", justifyContent:'center' }}>
                {subMenu.map((item) => (
                  <div
                    className="sub-menu-items"
                    onClick={() => window.location.assign(item.redirect)}
                  >
                    {item.key}
                  </div>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "end", gap: "10px" }}>
                  <img src={assests.Luxembourg} className="luxemborg-logo" />
                  <img src={assests.insta} className="insta-logo" />
                  <img src={assests.fb} className="fb-logo" />
                </Box>
                <Box>
                  {authStore && token && (
                    <Tooltip>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml:2, mt:1}}>
                        {authStore ? (
                          <Avatar
                            sx={{ border: "1px solid #80808075" }}
                            src={parseAuthStore.photo}
                          />
                        ) : (
                          <Avatar sx={{ border: "1px solid #80808075" }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem key={setting.key}>
                        <Typography
                          onClick={setting.callback}
                          textAlign="center"
                        >
                          {setting.key}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <div className="mob-appbar-container">
        <MobileMasthead />
      </div>
    </>
  );
}
export default Masthead;
