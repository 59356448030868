
export const isAuthorised = () => {
  const token = window.localStorage.getItem('token')
  const authStore = window.localStorage.getItem('AuthStore')
  let path;
  if (token && authStore) {
      const serialisedData = JSON.parse(authStore)
      if(serialisedData.profile_status == "pending"){
          if(serialisedData.profile_step == "mobile_verification"){
            path = '/user-verification'
          }else if(serialisedData.profile_step == "user_form"){
            path = '/user-registration'
          }else if(serialisedData.profile_step == "add_dog"){
            path = '/dog-registration'
          }
        }
        if(serialisedData.profile_status == "completed"){
          path = '/dashboard'
        }
      return path
  } else {
    path = '/login'
    localStorage.clear()
    return path
  }
}


export const formatArrayForCountry = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr, label: arr, value: arr })
  }
  return updatedArray
}

export const calculateOvernightTotalDays = (startDate, endDate) => {
  const dateA = new Date(`${startDate.date}T${startDate.time}`);
  const dateB = new Date(`${endDate.date}T${endDate.time}`);
  const timeDifferenceInMilliseconds = dateB - dateA;
  let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60); // In hours
  let timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60); // In Minutes
  timeDifferenceInHours = timeDifferenceInHours >=24 ? timeDifferenceInHours : 24
  const timeDifferenceInDays = timeDifferenceInHours >= 24 ? (timeDifferenceInHours / 24) | 0 : 1  // In Days
  const timeDifferenceInModulas = timeDifferenceInHours >= 24 ? timeDifferenceInHours % 24 : 0 // In Modulas
  const minutesDifferenceInModulas = timeDifferenceInMinutes % 60 // In Modulas minutes
  const dayDiff = timeDifferenceInModulas && ( (1 < timeDifferenceInModulas && timeDifferenceInModulas <= 5) && minutesDifferenceInModulas >= 0) ? `${timeDifferenceInDays + 0.5} Days` : (timeDifferenceInModulas > 5 && minutesDifferenceInModulas >= 0) ? `${timeDifferenceInDays + 1} Days` : timeDifferenceInDays
  const hoursDiff = timeDifferenceInHours < 24 ? `${timeDifferenceInHours} Hours` : ''
  const time = timeDifferenceInHours < 24 ? hoursDiff : dayDiff
  return time
}

