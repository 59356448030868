import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";

export const FacebookAuth = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyCL0-K3q21kg3iOU1urP2vSmIafH8GQbDk",
    authDomain: "dogstays-prod.firebaseapp.com",
    projectId: "dogstays-prod",
    storageBucket: "dogstays-prod.appspot.com",
    messagingSenderId: "1000054434040",
    appId: "1:1000054434040:web:6d56f624acf886d07ff30d",
    measurementId: "G-PV9JWJC643"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const FacebookProvider = new FacebookAuthProvider

  const auth = getAuth();
  signInWithPopup(auth, FacebookProvider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;

      console.log(accessToken, 'token')
      console.log(user, 'user')

      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);

      // ...
    });
};
