import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { CustomLabelInput, FormInput, InputField } from '../CustomElements';
import { useState } from 'react';
import SnackBar from '../Snackbar';
import PhoneInput from "react-phone-input-2";
import { intitateOtpforMobile, verifyOtpforMobile } from '../../Utils/Api';


 const UpdateMobileNumberDialog = ({handleCloseDialog, isOpen, reRenderData}) => {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState({isOpen: false, message: "", type: ""})
  const [mobileData, setMobileData] = useState({mobile: '', otp: '', otpInitiated: false, countryCode: ''})
  const [errMsg, setErrMsg] = useState('')

  const initiateOtpVerification = () => {
    intitateOtpforMobile({
      "mobile": mobileData.mobile,
      "country_code": mobileData.countryCode
  }).then((res) => {
    res.data.response_code == 80 && setMobileData({...mobileData, otpInitiated: true})
    res.data.response_code == 90 && setErrMsg(res.data.ui_message)
  })
  } 

  const validateOtpVerification = () => {
    verifyOtpforMobile({
      "mobile": mobileData.mobile,
      "country_code": mobileData.countryCode,
      "otp": mobileData.otp
  }).then((res) => {
    if(res.data.response_code == 80) { 
      setSnackbar({...snackbar, isOpen: true, message: 'Mobile Number Updated!', type: 'success'})
      handleClose()
      reRenderData()
    }
    res.data.response_code == 90 && setErrMsg(res.data.ui_message)
  })
  } 

  const handleClose = () => {
    handleCloseDialog()
    setMobileData({...mobileData, mobile: '', otp: '', otpInitiated: false, countryCode: ''})
  }

  React.useEffect(() => {
    setTimeout(() => {
      setErrMsg('')
    }, 5000)
  },[errMsg])

  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center'}} id="responsive-dialog-title">
          {"Update Mobile Number"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon'}}>
            {/* Do you want to delete this Address ? */}
          </DialogContentText>
          <Grid xs={12} container>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    New Mobile Number
                  </CustomLabelInput>
                  <PhoneInput
                   country={"lu"}
                   onChange={(value, data) => {
                    setMobileData({...mobileData, mobile: value.slice(data.dialCode.length), countryCode: data.dialCode})
                   }}
                  />
                </FormInput>
              </Grid>
          </Grid>
          {mobileData.otpInitiated && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
                Enter OTP
              </CustomLabelInput>
              <InputField
                type='text'
                size="small"
                placeholder="Enter OTP"
                value={mobileData.otp}
                onChange={(e) => setMobileData({...mobileData, otp: e.target.value})}
              />
            </FormInput>
          </Grid>}
          {errMsg && <p style={{color: 'red', fontSize: '14px'}}>{errMsg}</p>}
        </DialogContent>
        <DialogActions>
          <Button className="default-btn" onClick={() => handleClose()}>Close</Button>
          <Button className="default-btn" onClick={mobileData.otpInitiated ? validateOtpVerification : initiateOtpVerification}>{mobileData.otpInitiated ? 'Verify' : 'Update'}</Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={3000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </div>
  );
}

export default UpdateMobileNumberDialog