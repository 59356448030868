import { Box, Button, createTheme, Grid, ThemeProvider, Typography } from "@mui/material"
import Masthead from "../../../../Components/Masthead"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { FormInput, InputField, CustomLabelInput } from "../../../../Components/CustomElements"
import SnackBar from "../../../../Components/Snackbar"
import DynamicInfoDialog from "../../../../Components/Dialogs/dynamicInfoDialog"
import { PasswordResetReq } from "../../../../Utils/Api"
import VerifiedIcon from '@mui/icons-material/Verified';

const ResetPasswordModule = () => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    const signature = urlParams.get("signature");
    const email = urlParams.get("email");
    const theme = createTheme()
    const navigate = useNavigate()
    const [handlePasswords, setHandlePasswords] = useState({
        showPassword: false,
        passwordType: 'password',
        showCnfPassword: false,
        cnfpasswordType: 'password'
      })
    const [userForm, setUserForm] = useState({ password: '', cnfPassword: ''})
    const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
    const [passwordInfoDialog, setPasswordInfoDialog] = useState(false)
    const [errorMsg, setErrorMsg] = useState()
    const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
    const vaildPasswordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;


    const handleResetPassword = () => {
        if (vaildPasswordRegx.test(userForm.password)){
            if(userForm.password !== userForm.cnfPassword){
              setSnackbar({...snackbar, isOpen: true, message: "Password doesn't Match", type: 'error'})
            }else{
            PasswordResetReq({
              email: email,
              signature: signature,
              password: userForm.password
            }).then((res) => {
              if(res.data.response_code == 90){
                setSnackbar({...snackbar, isOpen: true, message: `${res.data.developer_message}`, type: 'error'})
                setErrorMsg(res.data.ui_message)
              }else if(res.data.response_code == 80){
                setIsPasswordResetSuccess(true)
              }
            })}
          }else{
            setPasswordInfoDialog(true)
        }
    }

    return(
        <ThemeProvider theme={theme}>
            <Masthead/>
            {!isPasswordResetSuccess ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                <Grid container lg={6} xs={12} px={4}>
                <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink='true' htmlFor="password">
                        New Password <span style={{color: 'red'}}>*</span>
                        </CustomLabelInput>
                        <p style={{fontSize: '14px', fontFamily:'Brandon', color: '#fe7648', margin: 0}}>Password must contain: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)</p>
                        <InputField
                        name="password"
                        type={handlePasswords.passwordType}
                        id="password"
                        size="small"
                        placeholder="Enter Password"
                        onChange={(e) => {setUserForm({...userForm, password: e.target.value})}}
                        value={userForm.password}
                        sx={{position: 'relative'}}
                        />
                    <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                    {!handlePasswords.showPassword ? 
                    <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: true, passwordType: 'text'})}/>
                    : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: false, passwordType: 'password'})}/>}
                    </Box>
                    </FormInput>
                </Grid>
                <Grid container sx={{display:'flex', alignItems:'end' }}>
                <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink='true' htmlFor="cnf_password">
                        Confirm Password <span style={{color: 'red'}}>*</span>
                        </CustomLabelInput>
                        <InputField
                        name="cnf_password"
                        type={handlePasswords.cnfpasswordType}
                        id="cnf_password"
                        size="small"
                        placeholder="Enter Password"
                        onChange={(e) => {setUserForm({...userForm, cnfPassword: e.target.value})}}
                        value={userForm.cnfPassword}
                        sx={{position: 'relative'}}
                        />
                    <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                    {!handlePasswords.cnfPassword ? 
                    <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: true, cnfpasswordType: 'text'})}/>
                    : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: false, cnfpasswordType: 'password'})}/>}
                    </Box>
                    </FormInput>
                </Grid>
                </Grid>
                {errorMsg && <Typography variant="caption" color={'red'} pt={2}>{errorMsg}</Typography>}
                <Grid item xs={12} sx={{ marginTop: "25px", textAlign: 'center'}}>
                    <Button variant="contained"  className="default-btn" sx={{width: "35%"}} onClick={handleResetPassword}>
                        Continue
                    </Button>
                </Grid> 
                
                </Grid>
            </div> : 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                <Box sx={{mt: 5}}>
                    <Box sx={{textAlign: 'center'}}>
                        <VerifiedIcon sx={{color: '#05a005', fontSize: '60px'}}/>
                    </Box>
                    <Box sx={{mt: 2, display: 'flex', justifyContent: 'center'}}>
                        <Box>
                        <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>Password Reset!</Typography>
                        <Typography sx={{mt:2, fontFamily: 'Brandon', fontSize: '22px', textAlign: 'center'}}>Please email us at contact@dogstays.lu if you have any questions.</Typography>
                        </Box>
                    </Box>
                <Grid xs={12} sx={{textAlign: 'center'}}>
                    <Button className="default-btn" variant="contained" sx={{ mt: 5, mb: 2, width: "25%" }} onClick={() => navigate('/login')} > Login </Button> 
                </Grid>
            </Box>
            </div>
            }
            <SnackBar
                open={snackbar.isOpen}
                handleClose={() => setSnackbar({...snackbar, isOpen: false})}
                duration={3000}
                msg={snackbar.message}
                type={snackbar.type}
            />
            <DynamicInfoDialog
                isOpen={passwordInfoDialog}
                handleCloseDialog={() => {setPasswordInfoDialog(false)}}
                title={"Password Must Contain:"}
                subtitle={"Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)"}
            />
        </ThemeProvider>

    )
}

export default ResetPasswordModule