import DogBookingRequestLayout from "../../Pages/Views/BookingRequest";
import Dashboard from "../../Pages/Views/MyDashboard";
import DogResgistration from "../../Pages/Views/DogRegistration";
import ThanksBooking from "../../Pages/Views/ThanksPages/booking";
import UserRegistrationForm from "../../Pages/Views/UserRegistration";
import UserVerification from "../../Pages/Views/UserVerification";
import UserLogin from "../Views/LogIn";
import UserSignUP from "../Views/SignUp";
import MyProfile from "../../Pages/Views/MyDashboard/MyProfile";
import MyDogs from "../../Pages/Views/MyDashboard/MyDogs";
import ViewDog from "../../Pages/Views/MyDashboard/MyDogs/view";
import EditDog from "../../Pages/Views/MyDashboard/MyDogs/Edit";
import CreateDog from "../../Pages/Views/MyDashboard/MyDogs/Create";
import CreateBookingRequest from "../../Pages/Views/MyDashboard/MyBookings/Create";
import MyMeeting from "../../Pages/Views/MyDashboard/MyMeetings";
import MyBookingRequests from "../../Pages/Views/MyDashboard/MyBookings";
import ViewMyBookingRequests from "../../Pages/Views/MyDashboard/MyBookings/View";
import BookingConsent from "../../Pages/Views/BookingConsent";
import ConsentThanks from "../../Pages/Views/BookingConsent/thankYou";
import MyPayments from "../../Pages/Views/MyDashboard/MyPayments";
import DogCareForm from "../../Pages/Views/MyDashboard/MyDogs/CareForm";
import PublicDogProfile from "../../Pages/Views/MyDashboard/MyDogs/PublicProfile";
import ResetPasswordModule from "../Views/ForgotPassword";


export const UsersRoutes = [
  {
    Element: UserRegistrationForm,
    path: "/user-registration",
  },
  {
    Element: Dashboard,
    path: "/dashboard",
  },
  {
    Element: DogResgistration,
    path: "/dog-registration",
  },
  {
    Element: DogBookingRequestLayout,
    path: "/booking-request",
  },
  {
    Element: ConsentThanks,
    path: "/thanks",
  },
  {
    Element: MyProfile,
    path: "/dashboard/profile",
  },
  {
    Element: MyDogs,
    path: "/dashboard/dog",
  }
  ,
  {
    Element: ViewDog,
    path: "/dashboard/dog/view/:dogId",
  },
  {
    Element: EditDog,
    path: "/dashboard/dog/edit/:dogId",
  },
  {
    Element: PublicDogProfile,
    path: "/dashboard/dog/public/:dogId",
  },
  {
    Element: CreateDog,
    path: "/dashboard/dog/create",
  },
  {
    Element: CreateBookingRequest,
    path: "/dashboard/booking/create",
  },
  {
    Element: MyMeeting,
    path: "/dashboard/meetings",
  },
  {
    Element: MyBookingRequests,
    path: "/dashboard/bookings_request",
  },
  {
    Element: ViewMyBookingRequests,
    path: '/dashboard/bookings_request/view/:bookingId'
  },{
    Element: BookingConsent,
    path: '/dashboard/bookings/details/:bookingId'
  },{
    Element: ConsentThanks,
    path: '/dashboard/bookings/details/confirmation'
  }, {
    Element: MyPayments,
    path: "/dashboard/payments",
  },
  {
    Element: DogCareForm,
    path: "/dashboard/dog/careForm/:dogId",
  },
];

export const otherRoutes = [
  {
    Element: UserLogin,
    path: "/login",
  },
  {
    Element: UserSignUP,
    path: "/register",
  },
  {
    Element: UserVerification,
    path: "/user-verification",
  },
  {
    Element: ResetPasswordModule,
    path: "/reset_password",
  }   
]