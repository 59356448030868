import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, NativeSelect, Typography } from "@mui/material";
import Masthead from "../../../../../../Components/Masthead";
import "../style.css";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../../../Components/CustomElements";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDogById, getDogdetailByid } from "../../../../../../Utils/Api";
import { BackBtn } from "../../../../../../Assets/assets";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DynamicAlertPopup from "../../../../../../Components/Dialogs/dynamicSweetAlert";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";

const ViewDog = () => {
  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [dogFormData, setDogFormData] = useState({
    name: null,
    breed: null,
    gender: null,
    photos: [],
    dob: null,
    is_neutered: null,
    age_category: null,
    size_category: null,
    is_friendly_with_other_dogs: null,
    is_friendly_with_other_dogs_text: null,
    is_comfortable_around_cat: null,
    is_comfortable_around_cat_text: null,
    chew_or_damage_things: null,
    chew_or_damage_things_text: null,
    can_left_alone: null,
    can_left_how_long: null,
    can_left_how_long_text: null,
    reaction_toward_children: null,
    reaction_toward_stranger: null,
    is_toilet_trained: null,
    is_toilet_trained_text: null,
    is_aggressive: null,
    is_aggressive_text: null,
    is_vaccinated: null,
    is_vaccinated_text: null,
    additional_info: null,
    dog_info_form: null,
    dog_id: null
  });
  const {
    name,
    breed,
    gender,
    photos,
    dob,
    is_neutered,
    age_category,
    size_category,
    is_friendly_with_other_dogs,
    is_friendly_with_other_dogs_text,
    is_comfortable_around_cat,
    is_comfortable_around_cat_text,
    chew_or_damage_things,
    chew_or_damage_things_text,
    can_left_alone,
    can_left_how_long,
    can_left_how_long_text,
    reaction_toward_children,
    reaction_toward_stranger,
    is_toilet_trained,
    is_toilet_trained_text,
    is_aggressive,
    is_aggressive_text,
    is_vaccinated,
    is_vaccinated_text,
    additional_info,
    dog_info_form,
    dog_id
  } = dogFormData;

  const { dogId } = useParams()

  const getDogDetails = async () => {
    const { data } = await getDogdetailByid(dogId)
    setDogFormData({
      ...dogFormData,
      name: data.data.name,
      breed: data.data.breed,
      gender: data.data.gender,
      photos: data.data.photos,
      dob: data.data.dob,
      is_neutered: data.data.is_neutered,
      age_category: data.data.age_category,
      size_category: data.data.size_category,
      is_friendly_with_other_dogs: data.data.is_friendly_with_other_dogs,
      is_friendly_with_other_dogs_text: data.data.is_friendly_with_other_dogs_text,
      is_comfortable_around_cat: data.data.is_comfortable_around_cat,
      is_comfortable_around_cat_text: data.data.is_comfortable_around_cat_text,
      chew_or_damage_things: data.data.chew_or_damage_things,
      chew_or_damage_things_text: data.data.chew_or_damage_things_text,
      can_left_alone: data.data.can_left_alone,
      can_left_how_long: data.data.can_left_how_long,
      can_left_how_long_text: data.data.can_left_how_long_text,
      reaction_toward_children: data.data.reaction_toward_children,
      reaction_toward_stranger: data.data.reaction_toward_stranger,
      is_toilet_trained: data.data.is_toilet_trained,
      is_toilet_trained_text: data.data.is_toilet_trained_text,
      is_aggressive: data.data.is_aggressive,
      is_aggressive_text: data.data.is_aggressive_text,
      is_vaccinated: data.data.is_vaccinated,
      is_vaccinated_text: data.data.is_vaccinated_text,
      additional_info: data.data.additional_info,
      dog_info_form: data.data.dog_info_form,
      dog_id: data.data.dog_id
    })
  }

  useEffect(() => {
    getDogDetails()
  }, [])

  const handleDeleteDog = () => {
    try {
      deleteDogById(dogId).then(() => {
        navigate('/dashboard/dog')
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box>
      <Masthead />
      <Box className="view-dog-container">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="wrap-back-btn" onClick={() => navigate('/dashboard/dog')}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
            View Dog Details
          </Typography>
          <Button className="dog-edit-action" onClick={() => navigate(`/dashboard/dog/edit/${dogId}`)}><EditNoteOutlinedIcon /> Edit</Button>
          <Button className="dog-delete-action" onClick={() => setIsDeleteDialogOpen(true)}><DeleteForeverIcon /> Delete</Button>
        </Box>
        <Box>
          <Typography
            fontFamily="Brandon"
            sx={{
              width: "100%",
              mb: 1,
              fontSize: "22px",
              textDecoration: "underline",
            }}
          >
            Basic Information
          </Typography>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Name</CustomLabelInput>
                  <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Name"
                    value={name}
                    disabled
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Breed</CustomLabelInput>
                  <InputField
                    type="text"
                    size="small"
                    placeholder="Enter "
                    value={breed}
                    disabled
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Gender</CustomLabelInput>
                  <NativeSelect
                    disableUnderline
                    defaultValue=""
                    value={gender}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    Date of Birth
                  </CustomLabelInput>
                  <InputField
                    name="date_of_birth"
                    type="date"
                    id="date_of_birth"
                    size="small"
                    placeholder="Enter DOB"
                    value={dob}
                    disabled
                  />
                </FormInput>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Neutered</CustomLabelInput>
                  <NativeSelect
                    disableUnderline
                    defaultValue=""
                    value={is_neutered}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Soon">Not yet but will be soon</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    What age category is your dog?
                  </CustomLabelInput>
                  <NativeSelect
                    disableUnderline
                    disabled
                    defaultValue=""
                    value={age_category}
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="young_puppy">
                      Very Young Puppy (less than 1 year old)
                    </option>
                    <option value="puppy">Puppy (less than 2 years old)</option>
                    <option value="adult_dog">
                      Adult dog (over 2 years old)
                    </option>
                    <option value="senior_dog">
                      Senior dog (above 7 years old)
                    </option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    What size category is your dog?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    disabled
                    value={size_category}
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="small">Small (less than 14 kilos)</option>
                    <option value="medium">
                      Medium (between 14 and 25 kilos)
                    </option>
                    <option value="large">Large (more than 25 kilos)</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography
            fontFamily="Brandon"
            sx={{
              width: "100%",
              mb: 1,
              mt: 3,
              fontSize: "22px",
              textDecoration: "underline",
            }}
          >
            Additional Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog friendly with other dogs?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    disabled
                    defaultValue=""
                    value={is_friendly_with_other_dogs}
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">It Depends</option>
                  </NativeSelect>
                </FormInput>
              </Grid>

              {is_friendly_with_other_dogs && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      disabled
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_friendly_with_other_dogs_text}
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                    Is your dog comfortable around cats?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    disabled
                    value={is_comfortable_around_cat}
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">Not Sure</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_comfortable_around_cat && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      disabled
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_comfortable_around_cat_text}
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                    Does your dog chew or damage things?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    value={chew_or_damage_things}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {chew_or_damage_things && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      disabled
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={chew_or_damage_things_text}
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Can your dog be left alone at home for some time?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    value={can_left_alone}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {can_left_alone && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      For how long?
                    </CustomLabelInput>
                    <NativeSelect

                      disableUnderline
                      defaultValue=""
                      value={can_left_how_long}
                      disabled
                      inputProps={{
                        style: {
                          fontFamily: "Brandon",
                          padding: "8.5px 14px",
                        },
                      }}
                      sx={{
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      <option selected disabled value="">
                        Select
                      </option>
                      1-2 hours, 2-4 hours, 4-6 hours, 6-8 hours, As long as
                      needed, Other
                      <option value="1-2 hours">1-2 hours</option>
                      <option value="2-4 hours">2-4 hours</option>
                      <option value="4-6 hours"> 4-6 hours</option>
                      <option value="6-8 hours"> 6-8 hours</option>
                      <option value="As long as needed">
                        {" "}
                        As long as needed
                      </option>
                      <option value="Other"> other</option>
                    </NativeSelect>
                  </FormInput>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="street_name">
                    How does your dog react to children?
                  </CustomLabelInput>
                  <InputTeaxtArea

                    maxRows={2}
                    value={reaction_toward_children}
                    disabled
                    size="small"
                    placeholder="Enter Details"
                    sx={{
                      fontFamily: "Brandon",
                      height: "40px",
                      fontSize: "1rem",
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      padding: "8.5px 14px",
                      marginTop: "5px",
                    }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="street_name">
                    How does your dog react to strangers?
                  </CustomLabelInput>
                  <InputTeaxtArea

                    maxRows={2}
                    size="small"
                    value={reaction_toward_stranger}
                    disabled
                    placeholder="Enter Details"
                    sx={{
                      fontFamily: "Brandon",
                      height: "40px",
                      fontSize: "1rem",
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      padding: "8.5px 14px",
                      marginTop: "5px",
                    }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog completely toilet trained?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    value={is_toilet_trained}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_toilet_trained && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      disabled
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_toilet_trained_text}
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog aggressive in any situation?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    value={is_aggressive}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_aggressive && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      disabled
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_aggressive_text}
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog fully vaccinated?
                  </CustomLabelInput>
                  <NativeSelect

                    disableUnderline
                    defaultValue=""
                    value={is_vaccinated}
                    disabled
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_vaccinated && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      disabled
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_vaccinated_text}
                    />
                  </FormInput>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true">
                  Is there anything else you would like us or the dog sitter to
                  know?
                </CustomLabelInput>
                <InputTeaxtArea
                  minRows={2}
                  disabled
                  sx={{
                    fontFamily: "Brandon",
                    height: "40px",
                    fontSize: "1rem",
                    border: "1px solid #ced4d9",
                    borderRadius: "3px",
                    padding: "8.5px 14px",
                    marginTop: "5px",
                  }}
                  size="small"
                  placeholder="Enter Details"
                  value={additional_info}
                />
              </FormInput>
            </Grid>
          </Grid>
        </Box>
        <Typography
          fontFamily="Brandon"
          sx={{
            width: "100%",
            mb: 1,
            mt: 5,
            fontSize: "22px",
            textDecoration: "underline",
          }}
        >
          Dog's Images
        </Typography>
        {photos.length > 0 && photos.map((item) => {
          return (
            <img className="view-dog-images" src={item} />
          )
        })}

      <Box>
        <Typography
          fontFamily="Brandon"
          sx={{
            width: "100%",
            mb: 1,
            fontSize: "22px",
            textDecoration: "underline",
          }}
        >
          Care Information for {name}
        </Typography>
        {dogFormData?.dog_info_form ? 
         <Box sx={{display: 'flex', gap: "10px", marginTop:'5px'}}>
          <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => navigate(`/dashboard/dog/careForm/${dog_id}`)}>View Form</Button>
          {/* <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => navigate(`/dashboard/dog/careForm/${dog_id}`)}>Edit</Button> */}
         </Box>
         : 
         <Box sx={{width:'150px'}} onClick={() => navigate(`/dashboard/dog/careForm/${dog_id}`)}>
          <Avatar className="new-dog-avatar">
            <AddTwoToneIcon className="new-dog-icon"/>
          </Avatar>
          <Typography fontFamily="Brandon" sx={{ textAlign: "center", fontWeight: 'bold'}}>
            Fill Care Form
          </Typography>
       </Box>
        }
      </Box>

    </Box>

      <DynamicAlertPopup isOpen={isDeleteDialogOpen} title={"Do you want to delete this dog?"} confirmBtnTxt="Delete" handleCloseDialog={() => setIsDeleteDialogOpen(false)} handleConfirm={handleDeleteDog}/>
    </Box>

  );
};

export default ViewDog;
