import React from 'react'
import Masthead from '../../../../../../Components/Masthead'
import BookingRequestForm from '../../../../../../Components/BookingRequestForm'
import '../style.css'
import { Box } from '@mui/material'

const CreateBookingRequest = () => {
    return(
        <>
        <Masthead/>
        <Box className='new-booking-container'>
        <BookingRequestForm/>
        </Box>
        </>
    )
}

export default CreateBookingRequest