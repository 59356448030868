import { useEffect, useState } from "react"
import { getBookingConstentDataById } from "../../../../Utils/Api"
import { useNavigate, useParams } from "react-router-dom"
import Masthead from "../../../../Components/Masthead"
import { Box, Typography } from "@mui/material"
import { BackBtn } from "../../../../Assets/assets"
import ConstentDates from "./Dates"
import ConstentDogsInfo from "./DogsInfo"
import ViewDogsInfo from "./DogsInfo/viewDogs"


const BookingConsent = () => {

    const navigate = useNavigate()
    const {bookingId} = useParams()
    const [bookingData, setBookingData] = useState([])
    const [bookingAt, setBookingAt] = useState('')
    const [bookingHeaders, setBookingHeaders] = useState({
        title: '',
        subTitle: ''
    })
    const [step, setStep] = useState(1)

    const getBookingConsentInfo = () => {
        getBookingConstentDataById(bookingId).then((res) => {
            setBookingData(res.data.data)
            localStorage.setItem("dogList", JSON.stringify(res.data.data.dogs.map((res) => {
                return {...res, isVerified: false}
            })))
        })
    }

    useEffect(() => {
        getBookingConsentInfo()
    }, [])
    

    const handleTitleAndBackStep = () => {
        let title;
        let subTitle;
        switch(step){
            case 1:
            title =  "Confirm Booking Details";
            subTitle = "Please confirm the final dates and times for your booking so we can process it. The dates and times you sent us in your request were:";
            break;
            case 2:
            title =  "Care Information";
            subTitle = "To confirm your booking we need a completed care information for each dog included in the booking. we will share this information with the dog sitter when we confirm the booking. If you have filled this form for dog before, you just need to check the last form and update any information that was changed";
            break;
            case 3:
            title =  `${JSON.parse(localStorage.getItem('selectedDog')).name} - Care Information`;
            subTitle = "Please edit/update your dog's form or use the button at the bottom to confirm that we can use the existing form for your dog's booking";
            break;
            default:
            console.log('error in function');
        }
        setBookingHeaders({...bookingHeaders, title: title, subTitle: subTitle})
    }

    useEffect(() => {
        handleTitleAndBackStep()
    }, [step])

    return(<>
        <Box>
            <Masthead />
            <Box className='constent-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
                    {step != 1 && <Box className="wrap-back-btn" onClick={() => { setStep(step - 1) }}>{BackBtn()} Back</Box>}
                    <Typography className="view-dog-heading">
                        {bookingHeaders.title}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>   
                <Typography variant="h6" className="view-sub-heading">
                        {bookingHeaders.subTitle}
                    </Typography>
                </Box>
                <Box>
                <p className="label-title" style={{fontFamily: 'Brandon', textAlign:'center', color:'#fe7648', fontSize:'16px'}}>Please use the change button below to select the final dates and times for the service and we will process your booking accordingly.</p>
                </Box>
                <Box>
                    {step == 1 ? <ConstentDates data={bookingData} step={(e) => setStep(e)} bookingLocation={(e) => setBookingAt(e)}/> : step == 2 ? <ViewDogsInfo step={step} data={bookingData} updateStep={(e) => setStep(e)} bookingAt={bookingAt}/> : <ConstentDogsInfo step={step}  data={bookingData} updateStep={(e) => setStep(e)}/>}
                </Box>
            </Box>
        </Box>
    </>)
}

export default BookingConsent