import React, { useEffect, useState } from "react"
import Masthead from "../../../../../Components/Masthead"
import { Avatar, Box, Button, Grid, NativeSelect, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { BackBtn } from "../../../../../Assets/assets"
import { getMeetingDetailsList } from "../../../../../Utils/Api"
import { CustomLabelInput, FormInput } from "../../../../../Components/CustomElements"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const MyBookingRequests = () => {
    const navigate = useNavigate()
    const [meetingList, setMeetingList] = useState([])
    const [listStatus, setListStatus] = useState('')
    const [filterOptions, setfilterOptions] = useState([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState()
    const [status, setStatus] = useState('')

    useEffect(() => {
        getMeetingDetailsList('', 1).then((res) => {
            setMeetingList(res.data.data.results)
            setCount(res.data.data.count)
            setfilterOptions(res.data.status)
        })
    }, [])
    const getMeetingList = (status) => {
        setStatus(status)
        getMeetingDetailsList(status, page).then((res) => {
            setMeetingList(res.data.data.results)
        })
    }
    const handleChangePage = (e, val) => {
        getMeetingDetailsList(status, val).then((res) => {
            setMeetingList(res.data.data.results)
        })
        setPage(val)
    }
    return (
        <Box>
            <Masthead />
            <Box className='profile-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
                    <Box className="wrap-back-btn" style={{ color: '#f48220', marginLeft: "5px" }} onClick={() => { navigate('/dashboard') }}> /Home</Box>
                    <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
                        My Booking Requests
                    </Typography>
                </Box>
                <Box sx={{mb: 2}}>
                    <Grid container xs={12} spacing={2}>
                    <Grid item xs={6} md={2}>
                    <FormInput fullWidth variant="standard">
                            <CustomLabelInput>
                            Status Filter
                            </CustomLabelInput>
                            <NativeSelect
                            disableUnderline
                            value={listStatus}
                            onChange={(e) =>  {getMeetingList(e.target.value); setListStatus(e.target.value)}}
                            inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                            sx={{ border: "1px solid #f48220", borderRadius: '3px', marginTop: '5px'}}
                            >   <option selected disabled value=''>Select</option>
                               {filterOptions.map((item, index) => {
                                return(
                                    <option value={item}>{item}</option>
                                )
                               })}
                            </NativeSelect>
                        </FormInput>
                    </Grid>
                    <Grid item xs={3} md={1} sx={{margin:'auto auto 0 0', marginBottom: '5px'}}>
                        <Button className="custom-btn" onClick={() =>  {getMeetingList(''); setListStatus('')}}>Reset</Button>
                    </Grid>
                    </Grid>
                </Box>
                <Box>
                {meetingList.length > 0 ? <Grid container xs={12}>
                {meetingList && meetingList.map((res) => {
                    const name = (res.dogs.map((item) => `${item.name} `))
                    return(
                        <Grid className="booking-req-container" spacing={2} item xs={12} md={6} lg={4}>
                            <Grid className="booking-req-wrapper" >
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p style={{ margin: '0'}}><span style={{color: '#f48221'}}>ID:</span> {res.booking_id}</p>
                                    <Box sx={{border: '1px solid #017d01', color: '#017d01', padding:'0px 10px', fontSize: '12px'}}>
                                        <p style={{margin: '10px 0px'}}>STATUS: <span style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{res.status}</span></p>
                                    </Box>
                                    {res.requested_for_changes && <Box sx={{border: '1px solid #d72828', color: '#d72828', padding:'0px 5px', fontSize: '12px'}}>
                                        <p style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Change Requested</p>
                                    </Box>}
                                </Box>
                                
                                <Box>
                                    <p className="label-heading">Service Type</p>
                                    <p className="label-title">{res.service_type} { res.service_type == "Daycare" && (res.day_care_type == 'one_time' ? " - One Time" : " - Weekly")}</p>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <p className="label-heading">Details</p>
                                    <p className="label-title">{res.dates}</p>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <p className="label-heading">Dog Name</p>
                                    <p className="label-title">{name}</p>
                                </Box>
                                <Box sx={{mt:2}}>
                                    <p className="label-heading">Status</p>
                                    <p className="label-title">{res.status}</p>
                                </Box>
                                <Box className="action-btn">
                                    <Button onClick={() => navigate(`/dashboard/bookings_request/view/${res.id}`)}>View Details</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                })}
                </Grid> : 
                 <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5, color: '#f48221' }}>
                 No Booking Request Available
             </Typography>
                }
                </Box>
                <Box className="pagination-data" sx={{display: 'flex', justifyContent: 'center'}}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(count/10)} rowsPerPage={10} color="primary" onChange={handleChangePage}/>
                    </Stack>    
                </Box>
            </Box>
        </Box>
    )
}

export default MyBookingRequests