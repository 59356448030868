import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { assests } from "../../../Assets/assets";
import { subMenu } from "../../../helper";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const MobileMasthead = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const authStore = window.localStorage.getItem('AuthStore')
  const token = window.localStorage.getItem('token')
  const parseAuthStore = JSON.parse(authStore)

  const navigate = useNavigate()

  const redirectToProfile = () => navigate('/dashboard/profile')

  const redirectToDashboard = () => navigate('/dashboard')

  const logout = () => {
    localStorage.clear()
    navigate('/login')
  }


  let settings = [{key:'My Profile', callback: redirectToProfile}, {key:'Dashboard' , callback: redirectToDashboard}, {key:'Logout' , callback: logout}];

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: "white",
        //   position: "fixed",
        //   top: 0,
          marginBottom: "50px"
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="black"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "flex" }, flexGrow: "2" }}>
            <img src={assests.dogstaysLogo} className="masthead-logo" />
          </Box>
            <div>
            {authStore && token && <Tooltip>
              <IconButton onClick={() => setIsSubMenuOpen(!isSubMenuOpen)} sx={{ p: 0 }}>
                {authStore ? <Avatar sx={{border: '1px solid #80808075'}} src={parseAuthStore.photo} /> : <Avatar sx={{border: '1px solid #80808075'}}/>}
              </IconButton>
            </Tooltip>}
              <Menu
                id="menu-appbar"
                anchorEl={isSubMenuOpen}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isSubMenuOpen}
                onClose={() => setIsSubMenuOpen(false)}
              >
                {settings.map((setting) => (
                <MenuItem key={setting.key}>
                  <Typography onClick={setting.callback} textAlign="center">{setting.key}</Typography>
                </MenuItem>
              ))}
              </Menu>
            </div>
        </Toolbar>
      </AppBar>

      {/* Drawer UI */}
      <Drawer anchor={"left"} open={isDrawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer}>
          <Box sx={{display: 'flex', mx:2}}>
            <IconButton
              size="large"
              edge="start"
              color="black"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: { xs: "flex" }, flexGrow: "2" }}>
              <img src={assests.dogstaysLogo} className="masthead-logo" />
            </Box>
          </Box>
          <List>
            <Box sx={{ textAlign: 'center', mt: 4}}>
              {subMenu.map((item) => (
                <div
                  className="mob-sub-menu-items"
                  onClick={() => window.location.assign(item.redirect)}
                >
                  {item.key}
                </div>
              ))}
            </Box>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileMasthead;
