import React, { useEffect, useState } from "react"
import { Avatar, Box, Button, Grid, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { cancelBookingStatusById, getMeetingDetailsById, updateBookingRequestStatusById } from "../../../../../../Utils/Api"
import { BackBtn } from "../../../../../../Assets/assets"
import Masthead from "../../../../../../Components/Masthead"
import ConfirmationTextDialog from "../../../../../../Components/Dialogs/confirmationTxt"

const ViewMyBookingRequests = () => {
    const navigate = useNavigate()
    const [meetingInfo, setMeetingInfo] = useState([])
    const [cancelBookingState, setCancelBookingState] = useState({
        isOpen: false,
        reason: ''
    })
    const [updateBookingState, setUpdateBookingState] = useState({
        isOpen: false,
        reason: ''
    })
    const {bookingId} = useParams()

    useEffect(() => {
        getMeetingDetailsById(bookingId).then((res) => {
            setMeetingInfo(res.data.data)
        })
    }, [])

    const locationType = meetingInfo.display_location_type

    const handleUpdateCancelStatus = () => {
        cancelBookingStatusById({
            "cancel_reason": cancelBookingState.reason
        },meetingInfo.booking_id).then((res) => {
            res.data.response_code == 80 && getMeetingDetailsById(bookingId).then((res) => {
            setMeetingInfo(res.data.data)
            setCancelBookingState({...cancelBookingState, isOpen: false})
            })
        })
    }

    const handleUpdateBookingReqStatus = () => {
        updateBookingRequestStatusById({
            "reason": updateBookingState.reason
        },meetingInfo.booking_id).then((res) => {
            res.data.response_code == 80 && getMeetingDetailsById(bookingId).then((res) => {
            setMeetingInfo(res.data.data)
            setUpdateBookingState({...updateBookingState, isOpen: false})
            })
        })
    }
    return (
        <Box>
            <Masthead />
            <Box className='profile-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mt:5 }}>
                    <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard/bookings_request') }}>{BackBtn()} Back</Box>
                    <Box className="wrap-back-btn" style={{ color: '#f48220', marginLeft: "5px" }} onClick={() => { navigate('/dashboard') }}> /Home</Box>
                </Box>
                <Box>
                <Grid container xs={12}>
                    <Box sx={{textAlign: 'center', width:'100%'}}>
                        <Typography sx={{letterSpacing: '1px', fontFamily: 'Brandon', fontSize:'24px', color: '#f48221',}}>Details of Booking Request</Typography>
                    </Box>
                    <Grid container xs={12} sx={{ mt:2}}>
                        <Box sx={{border: '1px solid #017d01', color: '#017d01', padding:'0px 10px'}}>
                            <p>STATUS: <span style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{meetingInfo.status}</span></p>
                        </Box>
                        {meetingInfo.requested_for_changes && <Box sx={{border: '1px solid #d72828', color: '#d72828', padding:'0px 10px', marginLeft: '10px'}}>
                            <p><span style={{fontWeight: 'bold', textTransform: 'uppercase'}}>Change Requested</span></p>
                        </Box>}
                    </Grid>
                    <Grid container xs={12} sx={{padding:'20px 0px', mt:2}}>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Booking Id</p>
                            <p className="booking-info-title">{meetingInfo.booking_id}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Service Type</p>
                            <p className="booking-info-title">{meetingInfo.service_type}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Dog(s)</p>
                            <p className="booking-info-title">{meetingInfo.dogs && meetingInfo.dogs.map((res, index) => {return(`${res.name},`)})}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Preferred Sitters</p>
                            <p className="booking-info-title">{meetingInfo.preferred_sitters_text || '-'}</p>
                        </Grid>
                        {/* <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Preferred Sitters Text</p>
                            <p className="booking-info-title">{meetingInfo.preferred_sitters_text || '-'}</p>
                        </Grid> */}
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Comfortable Splitting Booking</p>
                            <p className="booking-info-title">{meetingInfo.comfortable_splitting_booking || '-'}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Comfortable with overlap</p>
                            <p className="booking-info-title">{meetingInfo.sitter_caring_for_another_dog || '-'}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Flexibility</p>
                            <p className="booking-info-title">{(meetingInfo.daycare_dates_flexible ? 'Yes' : 'No') || '-'}</p>
                        </Grid>
                        <Grid xs={12} md={4} sx={{mt:2}}>
                            <p className="booking-info-label">Additional Details</p>
                            <p className="booking-info-title">{meetingInfo.other_details || '-'}</p>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid container xs={12}>
                    <Grid xs={12} md={4}>
                        <p className="booking-info-label">Dates</p>
                        <p className="booking-info-title">{meetingInfo.dates}</p>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <p className="booking-info-label">Total Days Count</p>
                        <p className="booking-info-title">{meetingInfo.total_day_count}</p>
                    </Grid>
                </Grid>
                <Grid container xs={12} sx={{mt: 5}}>
                    <Grid item xs={12} md={8}>
                        <Grid xs={12} sx={{mt:2}}>
                            <p className="booking-info-label">Acceptable Service Locations:</p>
                            {/* <p className="booking-info-title">{locationType}</p> */}
                        </Grid>
                        {meetingInfo.location_type && meetingInfo.location_type.includes('sitter_location') && <Grid xs={12} sx={{mt:2}}>
                            <p className="booking-info-title"><span>Sitter's Home</span></p>
                        </Grid>}
                        {meetingInfo.location_type && meetingInfo.location_type.includes('parent_location') && <Grid xs={12} sx={{mt:2}}>
                            <p className="booking-info-title"><span>My Home: </span>{meetingInfo.parent_address?.street_name + ' ' + meetingInfo.parent_address?.city + ' ' + meetingInfo.parent_address?.country + ' ' + meetingInfo.parent_address?.postal_code  || '-'}</p>
                        </Grid>}
                        {meetingInfo.location_type && meetingInfo.location_type.includes('other_address') &&  <Grid xs={12} sx={{mt:2}}>
                            <p className="booking-info-title"><span>Other Address: </span>{(meetingInfo?.manual_address && (meetingInfo?.manual_address?.street_name + ' ' + meetingInfo?.manual_address?.city + ' ' + meetingInfo?.manual_address?.country + ' ' + meetingInfo?.manual_address?.postal_code))  || '-'}</p>
                        </Grid>}
                    </Grid>
                    {(meetingInfo.status != 'Cancelled' && meetingInfo.status != 'Booking Completed' && !meetingInfo.requested_for_changes) && <Grid item xs={12} md={4}>
                       <Box sx={{display: 'flex', alignItems: 'end', gap: '20px', height: '100%'}}>
                            {meetingInfo?.is_cancellable && <Button className="default-btn" variant="contained" onClick={() => setCancelBookingState({...cancelBookingState, isOpen: true})}>Cancel Booking</Button>}
                            <Button className="default-btn" variant="contained" onClick={() => setUpdateBookingState({...updateBookingState, isOpen: true})}>Request To Change</Button>
                       </Box>
                    </Grid>}
                </Grid>
                </Box>
            </Box>
            {cancelBookingState.isOpen && <ConfirmationTextDialog
             sumbitTxt={'Cancel Booking'}
             cancelTxt={'Back To Booking'}
             isOpen={cancelBookingState.isOpen}
             title={'Want To Cancel The Booking?'}
             SubTitle={'Want to cancel your booking? Please tell us the reason. Please note: We will refund you only if you cancel in time according to our cancellation policy. Please email us at contact@dogstays.lu if you have any questions.'}
             reason={e => setCancelBookingState({...cancelBookingState, reason: e})}
             handleCloseDialog={() => setCancelBookingState({...cancelBookingState, isOpen: false})}
             handleClick={handleUpdateCancelStatus}
             />}

            {updateBookingState.isOpen && <ConfirmationTextDialog
             sumbitTxt={'Send Change Request'}
             cancelTxt={'Back To Booking'}
             isOpen={updateBookingState.isOpen}
             title={'Want To Update The Booking?'}
            //  SubTitle={'Please tell us why so we can update the dog sitter too:'}
             reason={e => setUpdateBookingState({...updateBookingState, reason: e})}
             handleCloseDialog={() => setUpdateBookingState({...updateBookingState, isOpen: false})}
             handleClick={handleUpdateBookingReqStatus}
             />}
        </Box>
    )
}

export default ViewMyBookingRequests
