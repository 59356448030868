import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import { CustomLabelInput, FormInput, InputField } from '../CustomElements';
import { useState } from 'react';
import { changePassword } from '../../Utils/Api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackBar from '../Snackbar';

 const ChangePasswordDialog = ({handleCloseDialog, isOpen}) => {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState({isOpen: false, message: "", type: ""})
  const [password,  setPassword] = useState({
    newPassword: '',
    newPasswordType: 'password',
    showNewPassword: false,
    cnfPassword: '',
    showCnfPassword: false,
    cnfpasswordType: 'password'
  })
  const [showMsg, setShowMsg] = useState({
    isShowMsg: false, 
    msg: ''
  })

  const { newPassword, newPasswordType, cnfPassword, cnfpasswordType } = password

  const handleChangePassword = () => {
    if(newPassword === cnfPassword){
      changePassword({password: newPassword}).then((res) => {
        if(res.data.response_code == 80){
          setSnackbar({...snackbar, isOpen: true, message: `Password Changed`, type: 'success'})
          setShowMsg({...showMsg, isShowMsg:true, msg: 'Password Updated'})
          // handleCloseDialog()
        } else if(res.data.response_code == 90){
          setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
        }
      })
    } else{
      setPassword({...password, newPassword: '', cnfPassword: ''})
      setSnackbar({...snackbar, isOpen: true, message: `Password doesn't match`, type: 'error'})

    }

  }

  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center'}} id="responsive-dialog-title">
          {"Update your Password"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon'}}>
            {/* Do you want to delete this Address ? */}
          </DialogContentText>
          <Grid xs={12} container>
          <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    New Password
                  </CustomLabelInput>
                  <InputField
                    type={password.newPasswordType}
                    size="small"
                    placeholder="Enter Password"
                    value={newPassword}
                    onChange={(e) => setPassword({...password, newPassword: e.target.value})}
                  />
                  <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!password.showNewPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setPassword({...password, showNewPassword: true, newPasswordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setPassword({...password, showNewPassword: false, newPasswordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    Confirm Password
                  </CustomLabelInput>
                  <InputField
                    type={password.cnfpasswordType}
                    size="small"
                    placeholder="Enter Password"
                    value={cnfPassword}
                    onChange={(e) => setPassword({...password, cnfPassword: e.target.value})}
                  />
                <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!password.showCnfPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setPassword({...password, showCnfPassword: true, cnfpasswordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setPassword({...password, showCnfPassword: false, cnfpasswordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              {showMsg.isShowMsg && <h3 style={{fontFamily: 'Brandon', color: 'green', textAlign: 'center', width: '100%', marginBottom: '0px', background: '#00800024'}}>{showMsg.msg}</h3>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="default-btn"  onClick={() => { handleCloseDialog(); setShowMsg({...showMsg, isShowMsg:false, msg: ''}); setPassword({...password, newPassword: '', cnfPassword: ''})}}>Close</Button>
          {!showMsg.isShowMsg && <Button className="default-btn" onClick={handleChangePassword}>Change</Button>}
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={3000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </div>
  );
}

export default ChangePasswordDialog