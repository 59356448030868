import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { googleLogin } from "../../Utils/Api";
import { removeItem, setItem } from "../../Utils/localStorage";
import { useNavigate } from "react-router-dom";

export const GoogleAuth = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyCL0-K3q21kg3iOU1urP2vSmIafH8GQbDk",
    authDomain: "dogstays-prod.firebaseapp.com",
    projectId: "dogstays-prod",
    storageBucket: "dogstays-prod.appspot.com",
    messagingSenderId: "1000054434040",
    appId: "1:1000054434040:web:6d56f624acf886d07ff30d",
    measurementId: "G-PV9JWJC643"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const auth = getAuth();
  const isRedirect = window.sessionStorage.getItem('isRedirected')
  const googleProvider = new GoogleAuthProvider();
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      setItem('email', user.email)
      googleLogin({access_token: user.accessToken}).then((res) => {
        removeItem('origin')
        removeItem('access_token')
        setItem('origin', 'social')
        setItem('token', res.data.data.token)
        setItem('AuthStore', JSON.stringify(res.data.data))
        if(res.data.data.profile_status == "pending"){
          if(res.data.data.profile_step == "mobile_verification"){
            window.location.href='/user-verification'
          }else if(res.data.data.profile_step == "user_form"){
            window.location.href='/user-registration'
          }else if(res.data.data.profile_step == "add_dog"){
            window.location.href='/dog-registration'
          }
        }
        if(res.data.data.profile_status == "completed"){
          if(isRedirect) { 
            window.location.href = isRedirect
            sessionStorage.clear()
           }
          else window.location.href='/dashboard'
        }
      })
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};
