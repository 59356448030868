import ConstentDaycareDates from "./daycare";
import ConstentOvernightDates from "./overnight";

const ConstentDates = ({ data, step , bookingLocation}) => {
  return (
    <>
      {data.service_type == "Daycare" && <ConstentDaycareDates data={data} updateStep={(e) => step(e)} bookingAt={(e) => bookingLocation(e)}/>}
      {data.service_type == "Overnight Boarding" && <ConstentOvernightDates data={data} updateStep={(e) => step(e)} bookingAt={(e) => bookingLocation(e)}/>}
    </>
  );
};

export default ConstentDates;
