import React, { useEffect, useMemo, useState } from "react";
import Select from 'react-select'
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { assests } from "../../../../Assets/assets";
import { Box, Button, Card, Fab, NativeSelect, Typography } from "@mui/material";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../Components/CustomElements";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { languagesOptions } from "../../../../helper";
import { useNavigate } from "react-router-dom";
import { ImageUpload, updateUserInfo } from "../../../../Utils/Api";
import SnackBar from "../../../../Components/Snackbar";
import MultiUpload from "../../../../Components/MutipleUpload";
import { isAuthorised } from "../../../../Utils/helper";
import { setItem } from "../../../../Utils/localStorage";
import Masthead from "../../../../Components/Masthead";
import '../style.css'


const UserRegistrationForm = () => {
  const theme = createTheme();
  const navigate = useNavigate()
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
  const [images, setImages] = useState([])
  const [userFormData, setUserFormData] = useState({
    hear_from_source: '',
    gender: '',
    date_of_birth: '',
    photo: '',
    using_whatsapp_messenger: '',
    another_whatsapp_mobile:'',
    preferedLanguage: [],
    street_name: '',
    city: '',
    country: '',
    postal_code: '',
  })
  const { hear_from_source, gender, photo, date_of_birth, using_whatsapp_messenger, another_whatsapp_mobile, preferedLanguage, street_name, city, country, postal_code} = userFormData
  const countryOptions = useMemo(() => countryList().getData(), [])
  const currentDate = new Date().toISOString().substring(0,10)

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const handleUpdateSitterData = async() => {
    let formData = new FormData()
    let ImgUrl = ''
    images.map((res) => {
      formData.append('image', res)
    })
    if(images.length > 0){
      await ImageUpload(formData).then((res) => {
        const data = res.data.data.url
        const list = data.map((url) => {return url})
        ImgUrl = list.toString()
        if(res.data.response_code == 80){
          setSnackbar({...snackbar, isOpen: true, message: "Profile Image Uploaded", type: 'success'})
        }
      })
    }
    await updateUserInfo({
      hear_from_source: hear_from_source,
      gender: gender,
      date_of_birth: date_of_birth,
      photo: ImgUrl,
      using_whatsapp_messenger: using_whatsapp_messenger,
      street_name: street_name,
      city: city,
      country: country.label,
      postal_code: postal_code,
      another_whatsapp_mobile: another_whatsapp_mobile,
      preferred_languages: formatMultiSelectData(preferedLanguage),
      }).then((res) => {
        const authStore = window.localStorage.getItem('AuthStore')
        const serialisedData = JSON.parse(authStore)
        const newResponse = res.data.data
        const merge = {...serialisedData, ...newResponse}
        setItem('AuthStore', JSON.stringify(merge))
      })
  }

  const handleSubmit = async() => {
    if(hear_from_source == '' || gender == '' || date_of_birth == '' || using_whatsapp_messenger == '' || street_name == '' ||
    city == '' || country == '' || postal_code == '' || preferedLanguage == []
    ){
      setSnackbar({...snackbar, isOpen: true, message: "Oops! Looks like you have missed a question", type: 'error'})
    } else{
    await handleUpdateSitterData()
    navigate('/dog-registration')
  }
  };



  return (
    <>
    <ThemeProvider theme={theme}>
      <Masthead/>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={4} sx={{
            backgroundImage: `url(${assests.bulldogBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" 
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: '100vh',
          }}
        />
        <Grid className="hide-scrollbar" item xs={12} sm={10} md={8} component={Card} elevation={6} sx={{backgroundColor: '#ffff', height:'100%', overflowY: 'scroll'}}>
          <Typography component='h1' fontFamily='Brandon' sx={{width: '100%', textAlign: 'center', mt: 5, mb:5, fontSize:'28px'}}>Your Details</Typography>
          <Box
            className='form-registration-container'
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
          <Grid container spacing={2} className="form-registration-wrapper">
            <Grid item xs={12} md={6} sx={{ display:'block' }}>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                 How did you hear about DogStays? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  value={hear_from_source}
                  onChange={(e) => setUserFormData({...userFormData, hear_from_source: e.target.value})}
                  name="hear_from_source"
                  id="hear_from_source"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Google Search'>Google Search</option>
                    <option value='Facebook group'>Facebook group</option>
                    <option value='Through a friend'>Through a friend</option>
                    <option value='Booked With DogStays before'>Booked With DogStays before</option>
                    <option value='Facebook Ad'>Facebook Ad</option>
                    <option value='Google Ad'>Google Ad</option>
                    <option value='Other'>Other</option>
                    
                </NativeSelect>
              </FormInput>
            </Grid>

            {hear_from_source == "Other" &&
            <Grid item xs={12} md={10}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true" >
              Please Share the Details
              </CustomLabelInput>
              <InputTeaxtArea
                size="small"
                placeholder="Enter Details"
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px",  marginTop:"5px"}}
              />
            </FormInput>
          </Grid>
            }

            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor=" ">
                 Gender <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="gender"
                  id="gender"
                  value={gender}
                  onChange={(e) => setUserFormData({...userFormData, gender: e.target.value})}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                    <option value='other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Your Date of birth <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  name="date_of_birth"
                  type="date"
                  id="date_of_birth"
                  size="small"
                  value={date_of_birth}
                  InputProps={{ inputProps: { max: currentDate} }}
                  onChange={(e) => setUserFormData({...userFormData, date_of_birth: e.target.value})}
                  placeholder="Enter DOB"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  value={using_whatsapp_messenger}
                  onChange={(e) => setUserFormData({...userFormData, using_whatsapp_messenger: e.target.value})}
                  name="using_whatsapp_messenger"
                  id="using_whatsapp_messenger"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {using_whatsapp_messenger == 'true' && 
              <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink='true' htmlFor="another_whatsapp_mobile" sx={{marginBottom: '5px'}}>
                If the number you use on WhatsApp is different please share that with us here
                </CustomLabelInput>
                <PhoneInput
                  country={"lu"}
                  value={another_whatsapp_mobile}
                  onChange={(value) => setUserFormData({...userFormData, another_whatsapp_mobile: value})}
                  name="another_whatsapp_mobile"
                  id="another_whatsapp_mobile"
                />
              </FormInput>
            </Grid>}
            </Grid>

            <Grid item xs={12} md={6} sx={{display: 'block'}}>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Street Name and Number <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={street_name}
                  onChange={(e) => setUserFormData({...userFormData, street_name: e.target.value})}
                  placeholder="Enter Street Name and Number"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                Area Name + City (Example: Merl, Luxembourg City) 
                <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={city}
                  onChange={(e) => setUserFormData({...userFormData, city: e.target.value})}
                  placeholder="Enter City"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Postal Code <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={postal_code}
                  onChange={(e) => setUserFormData({...userFormData, postal_code: e.target.value})}
                  placeholder="Enter Postal Code"
                />
              </FormInput>
            </Grid>            
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true"  sx={{marginBottom: '5px'}}>
                  Country <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <Select
                className='select-font'
                options={countryOptions}
                value={country}
                onChange={(value) => setUserFormData({
                  ...userFormData,
                  country: value
                })}
              />
              </FormInput>
            </Grid>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink='true' htmlFor="another_whatsapp_mobile" sx={{marginBottom: '5px'}}>
                Which languages do you speak or understand? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <Select
                className='select-font'
                options={languagesOptions}
                value={preferedLanguage}
                isMulti
                onChange={(value) => setUserFormData({
                  ...userFormData,
                  preferedLanguage: value
                })}
                name='preferred_languages'
                placeholder='Please select multiple languages'
              />
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={11}>
              <MultiUpload text='Please upload your profile picture' imageAssets={(res) => setImages(res)} limit={1}/>
            </Grid>

            <Grid item xs={12} sx={{display: 'flex', justifyContent:'center'}}>
            <Button
                type="submit"
                className="default-btn"
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, width: "25%" }}
              >
                Continue
              </Button>
            </Grid>
 
          </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={3000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
  );
};

export default UserRegistrationForm;
