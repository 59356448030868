export const setItem = (type, item) =>{
    window.localStorage.setItem(type, item)
}

export const getItem = (type) =>{
    window.localStorage.getItem(type)
}

export const removeItem = (item) =>{
    window.localStorage.removeItem(item)
}