import { useEffect, } from 'react'
import { useNavigate } from 'react-router'
import { isAuthorised } from '../../Utils/helper'

const DecisionComponent = () => {
  const navigate = useNavigate()
  const path = isAuthorised()
  useEffect(() => {
    const pathName = window.location.pathname
    pathName.includes('/dashboard/bookings/details/') && sessionStorage.setItem('isRedirected', pathName)
    navigate(path)
  }, [])

  return null
}

export default DecisionComponent