import { Alert, Snackbar } from "@mui/material"

const SnackBar = ({open, handleClose, type, duration, msg}) => {
    return(
        <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>{msg}</Alert>
      </Snackbar>
    )
}

export default SnackBar