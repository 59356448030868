import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import newbooking  from  "../../Assets/Images/newbooking.png"
import screen1  from  "../../Assets/Images/Screen 1.png"
import screen2  from  "../../Assets/Images/Screen 2.png"
import screen3  from  "../../Assets/Images/Screen 3.png"
import screen4  from  "../../Assets/Images/Screen 4.png"
import screen5  from  "../../Assets/Images/Screen 6.png"
import screen6  from  "../../Assets/Images/Screen 6a.png"
import "./style.css"
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

const FirstLoginFlow = () => {
    const [renderBanner, setRenderBanner] = useState(true)
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const CloseBanner = () => {
        return (
          <div className="carousel-close-button" onClick={() => setRenderBanner(false)}><CloseIcon/></div>
        );
      };
    return(
        <>
            {renderBanner && <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            customButtonGroup={<CloseBanner />}
            >
            <div style={{padding: '20px'}}>
                <img src={screen1} style={{width: "100%"}}/>
                <p style={{fontWeight: 'bold'}}>Welcome to your new DogStays Dashboard!</p>
                <p>We're super excited to have you here. To make managing your bookings really easy,
                we've created an account area just for you which is packed with new features. By logging
                into your account you now easily send us booking requests, update your dog's profile and care details,
                manage your personal information, view upcoming appointments, track payments, and much more.</p>
            </div>
            <div style={{padding: '20px'}}>
                <img src={screen2} style={{width: "100%"}}/>
                <p>Now you can quickly send us a booking request by selecting your dog(s) and answering a few questions about the booking. We will automatically use your dog's profile to find your dog the best match from our community!</p>
            </div>
            <div style={{padding: '20px'}}>
                <img src={screen3} style={{width: "100%"}}/>
                <p>Easily check and update your dog's basic profile, daily schedule and care information so it is always up to date for bookings.</p>
            </div>
            <div style={{padding: '20px'}}>
                <img src={screen4} style={{width: "100%"}}/>
                <p>In just a few clicks you can now view details of upcoming or past bookings, check the status
                    of a booking and even request us for a change if needed.</p>
            </div>
            <div style={{padding: '20px'}}>
                <img src={screen5} style={{width: "100%"}}/>
                <p>Now all your meetings and payments are also in one place. You can easily view details, track statuses and download your invoices when needed.</p>
            </div>
            <div style={{padding: '20px'}}>
                <img src={screen6} style={{width: "100%"}}/>
                <p>Finally, click your photo in the top right corner of your dashboard to access your own profile information.
                    You can update your phone number, edit the list of addresses we have for you or change your DogStays password,
                    all in just a few moments!</p>
            </div>
            </Carousel>}
        </>
    )
}

export default FirstLoginFlow