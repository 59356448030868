import { CheckBox } from "@mui/icons-material";
import { FormControl, Link, MenuItem, Select, TextField, TextareaAutosize, ToggleButton, ToggleButtonGroup, Typography, withStyles } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InputField = styled(TextField)({
  marginTop: "5px",
  "& label.Mui-focused": {
    color: "#fe7648",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ced4da",
    },
    "&:hover fieldset": {
      borderColor: "pink",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #fe7648",
    },
    "& .MuiInputBase-input": {
      fontFamily: "Brandon",
      fontSize: "1rem",
    },
  },
});

export const InputTeaxtArea = styled(TextareaAutosize)(
  ({ theme }) => `
  margin-top: 5px
  &:hover {
  }
  &:focus {
    outline: none
  }
`,
);

export const CustomLabelInput = styled(Typography)({
  color: "black",
  fontSize: "14px",
  fontFamily: "Brandon",
  lineHeight: "16px",
});

export const FormInput = styled(FormControl)({
    marginTop: '10px'
  });

export function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" fontFamily='brandon' align="center" {...props}>
        {'Copyright © '}
          Dogstays 
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

 export const DividerRoot = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  export const CustomSelect = styled(Select)({
    marginTop: '6px',
    border: "1px solid #ced4d9",
    borderRadius: 5,
    padding: '4.5px',
    background: 'transparent'
  });
  
  export const CustomMenuItem = styled(MenuItem)({
    '&.Mui-selected': {
      backgroundColor: '#fe7648',
      color: 'white',
    },
  });


  export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    grouped: {
      margin: theme.spacing(2),
      padding: theme.spacing(0, 1),
      "&:not(:first-child)": {
        border: "1px solid",
        borderColor: "#692B7C",
        borderRadius: "50%"
      },
      "&:first-child": {
        border: "1px solid",
        borderColor: "#692B7C",
        borderRadius: "50%"
      }
    }
  }));
  
  export const StyledToggle = styled(ToggleButton)({
    root: {
      color: "#692B7C",
      "&$selected": {
        color: "white",
        background: "#692B7C"
      },
      "&:hover": {
        borderColor: "#BA9BC3",
        background: "#BA9BC3"
      },
      "&:hover$selected": {
        borderColor: "#BA9BC3",
        background: "#BA9BC3"
      },
      minWidth: 32,
      maxWidth: 32,
      height: 32,
      textTransform: "unset",
      fontSize: "0.75rem"
    },
    selected: {}
  });