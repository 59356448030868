import axios from "axios";

const baseUrl = "https://api.dogstays.lu";

const authToken = { Authorization: `Bearer ${window.localStorage.getItem('token')}`}

const getAuthToken = () => {
  return { Authorization: `Bearer ${window.localStorage.getItem('token')}`}
}

export const ImageUpload = (image) => {
  return axios.post(`${baseUrl}/basic/multi/upload/image`, image)
}

export const createOTP = (type) => {
  return axios.post(`${baseUrl}/account/parent/create/otp`, type);
};

export const verifyEmailOTP = (payload) => {
  return axios.post(`${baseUrl}/account/parent/verify/email/otp`, payload);
};

export const verifyMobileOTP = (payload) => {
    return axios.post(`${baseUrl}/account/parent/verify/mobile/otp`, payload, {   
      headers: getAuthToken()
    });
};

export const emailLogin = (payload) => {
  return axios.post(`${baseUrl}/account/parent/login`, payload);
}

export const googleLogin = (payload) => {
  return axios.post(`${baseUrl}/account/parent/google/login`, payload);
}

export const updateUserInfo = (payload) => {
  return axios.put(`${baseUrl}/account/parent/profile/form`, payload, {
    headers: getAuthToken()
  });
}

export const updateParentInfo = (payload) => {
  return axios.put(`${baseUrl}/account/parent/edit/profile`, payload, {
    headers: getAuthToken()
  });
}

export const addDog = (payload) => {
  return axios.post(`${baseUrl}/account/parent/add/dog`, payload, {
    headers: getAuthToken()
  });
}

export const getDogCounts = () => {
  return axios.get(`${baseUrl}/account/parent/dog/count`, {
    headers: getAuthToken()
  });
}

export const getDogList = () => {
  return axios.get(`${baseUrl}/account/parent/dog/list`, {
    headers: getAuthToken()
  });
}

export const getParentAddress = () => {
  return axios.get(`${baseUrl}/account/parent/self/address`, {
    headers: getAuthToken()
  });
}

export const getSelfProfile = () => {
  return axios.get(`${baseUrl}/account/parent/self/profile`, {
    headers: getAuthToken()
  });
}

export const deleteAddress = (id,payload) => {
  return axios.put(`${baseUrl}/account/parent/address/delete/${id}`,payload, {
    headers: getAuthToken()
  });
}

export const getDogdetailByid = (id) => {
  return axios.get(`${baseUrl}/account/parent/dog/details/${id}`, {
    headers: getAuthToken()
  });
}

export const updateDogdetailById = (payload, id) => {
  return axios.put(`${baseUrl}/account/parent/dog/edit/${id}`,payload,  {
    headers: getAuthToken()
  });
}

export const changePassword = (payload) => {
  return axios.put(`${baseUrl}/account/parent/change/password`,payload,  {
    headers: getAuthToken()
  });
}

export const addNewAddress = (payload) => {
  return axios.post(`${baseUrl}/account/parent/create/address`,payload,  {
    headers: getAuthToken()
  });
}

export const createBookingReq = (payload) => {
  return axios.post(`${baseUrl}/booking/create`,payload,  {
    headers: getAuthToken()
  });
}

export const updateAddressById = (payload, id) => {
  return axios.put(`${baseUrl}/account/parent/edit/address/${id}`,payload,  {
    headers: getAuthToken()
  });
}

export const deleteDogById = (id, payload) => {
  return axios.put(`${baseUrl}/account/parent/dog/delete/${id}`, payload,  {
    headers: getAuthToken()
  });
}

export const getMeetingDetailsList = (status, page) => {
  return axios.get(`${baseUrl}/booking/request/list?status=${status}&page=${page}`, {
    headers: getAuthToken()
  });
}

export const getMeetingDetailsById = (id) => {
  return axios.get(`${baseUrl}/booking/details/${id}`, {
    headers: getAuthToken()
  });
}

export const getBookingConstentDataById = (id) => {
  return axios.get(`${baseUrl}/booking/details/by_booking_id/${id}`, {
    headers: getAuthToken()
  });
}

export const updateConstentDataById = (id, payload) => {
  return axios.put(`${baseUrl}/booking/update_dog_form/${id}`, payload,  {
    headers: getAuthToken()
  });
}

export const updateBookingById = (id, payload) => {
  return axios.put(`${baseUrl}/booking/date/update/${id}`, payload,  {
    headers: getAuthToken()
  });
}

export const deleteBookingById = (id, payload) => {
  return axios.put(`${baseUrl}/booking/delete/dog/form/${id}`, payload, {
    headers: getAuthToken()
  });
}

export const getMeetingList = (page) => {
  return axios.get(`${baseUrl}/booking/meeting/list?page=${page}`, {
    headers: getAuthToken()
  });
}

export const updateMeetingStatusById = (payload, id) => {
  return axios.put(`${baseUrl}/booking/meeting/update/${id}`, payload, {
    headers: getAuthToken()
  });
}

export const getPaymentList = (page) => {
  return axios.get(`${baseUrl}/booking/payment/list?page=${page}`, {
    headers: getAuthToken()
  });
}

export const submitMeetingStatusById = (payload, id) => {
  return axios.put(`${baseUrl}/booking/submit/doginfo/${id}`, payload, {
    headers: getAuthToken()
  });
}

export const cancelBookingStatusById = (payload, id) => {
  return axios.put(`${baseUrl}/booking/cancel/${id}`, payload, {
    headers: getAuthToken()
  });
}

export const updateBookingRequestStatusById = (payload, id) => {
  return axios.put(`${baseUrl}/booking/update/request/${id}`, payload, {
    headers: getAuthToken()
  });
}

export const intitateOtpforMobile = (payload) => {
  return axios.post(`${baseUrl}/account/parent/initiate/mobile/update`, payload, {
    headers: getAuthToken()
  });
}

export const verifyOtpforMobile = (payload) => {
  return axios.post(`${baseUrl}/account/parent/update/mobile/verify`, payload, {
    headers: getAuthToken()
  });
}

export const getPastSittersList = () => {
  return axios.get(`${baseUrl}/booking/past/sitters/list`, {
    headers: getAuthToken()
  });
}

export const forgotPasswordSendLink = (payload) => {
  return axios.post(`${baseUrl}/account/parent/reset/password/send-link`, payload, {
    headers: getAuthToken()
  });
}

export const PasswordResetReq = (payload) => {
  return axios.post(`${baseUrl}/account/parent/reset/password`, payload, {
    headers: getAuthToken()
  });
}